<template>
  <main class="home" role="main">
    <div class="main__header" v-if="!$store.state.managerDesktop">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <div v-if="$store.state.me.role === 'partner'" class="small-buttons">
          <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
            <SettingsIcon/>
          </a>
          <!-- <a class="btn btn--small btn--white" href="#" @click.prevent="forceRerender">
            <i>
              <RefreshIcon/>
            </i>
          </a> -->
        </div>
      </div>
    </div>
    <div v-if="!['debtcontrol', 'partner', 'agent'].includes($store.state.me.role) && !$store.state.managerDesktop" class="main__header main__header--secondary">
      <div class="toper__item left">
<!--        <mq-layout :mq="['md', 'lg', 'xlg', 'xl']" class="small-buttons">-->
<!--          <a class="btn btn&#45;&#45;small" href="#">-->
<!--            <PhoneIcon/>-->
<!--          </a>-->
<!--          <a class="btn btn&#45;&#45;small" href="#">-->
<!--            <InternetIcon/>-->
<!--          </a>-->
<!--        </mq-layout>-->
        <label class="tbl__filter__item">
          <v-select
            appendToBody
            :options="
              filterDropName.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            "
            placeholder="Сотрудник"
            @input="onUserChange"
            :value="
              filter.responsible_user_id
                ? {
                    value: filter.responsible_user_id,
                    label: filterDropName.find(
                      (s) => s.id === filter.responsible_user_id
                    ).name,
                  }
                : null
            "
          >
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon />
              </div>
            </template>
          </v-select>
        </label>
        <label class="tbl__filter__item">
          <v-select
            appendToBody
            :options="
              extendedGroup.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            "
            placeholder="Подразделение"
            @input="onGroupChange"
            :value="
              filter.group_id
                ? {
                    value: filter.group_id,
                    label: extendedGroup.find(
                      (s) => s.id === filter.group_id
                    ).name,
                  }
                : null
            "
          >
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes">
                <DownIcon />
              </div>
            </template>
          </v-select>
        </label>
      </div>
      <div class="toper__item">
        <div class="button-group">
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'today' }" @click="setSortType('today')">
            <span>Сегодня</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'tomorrow' }" @click="setSortType('tomorrow')">
            <span>Вчера</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'week' }" @click="setSortType('week')">
            <span>Неделя</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.trades.sort2 === 'month' }" @click="setSortType('month')">
            <span>Месяц</span>
          </button>
          <button type="button" @click="openPeriod = true" :class="{ active: $store.state.data.trades.sort2 === 'period' && $store.state.data.trades.sortDate }" ref="buttonPeriod" v-click-outside="vcoConfig" class="widgets__table__field">
            <i>
              <CalendarIcon/>
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker v-model="$store.state.data.trades.sortDate" ref="pickerPeriod" inline range/>
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item right">
<!--        <mq-layout :mq="['xs', 'sm', 'xsm']" class="small-buttons">-->
<!--          <a class="btn btn&#45;&#45;small" href="#">-->
<!--            <PhoneIcon/>-->
<!--          </a>-->
<!--          <a class="btn btn&#45;&#45;small" href="#">-->
<!--            <InternetIcon/>-->
<!--          </a>-->
<!--        </mq-layout>-->
        <div class="small-buttons">
          <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
            <SettingsIcon/>
          </a>
          <a class="btn btn--small btn--white" href="#" @click.prevent="forceRerender">
            <i>
              <RefreshIcon/>
            </i>
          </a>
        </div>
      </div>
    </div>
    <div class="main__content">
      <!-- <div v-if="$apollo.loading && $store.state.me.role !== 'debtcontrol'" class="messages__status">Идёт загрузка данных...</div> -->
      <div v-if="$apollo.loading && $store.state.me.role !== 'debtcontrol' && !$store.state.managerDesktop" class="widgets">
        <SplitWidget
          v-for="(_, index) in Array(20).fill(0)"
          :key="index"
          :fields="{
            class: 'sceleton',
            items: [
              {
                class: 'text-orange',
                title: ' '
              }
            ]
          }"
        />
      </div>
      <div v-if="$store.state.managerDesktop">
        <ManagerDesktop/>
      </div>
      <div v-else-if="$store.state.me.role === 'debtcontrol'">
        <OsDesktop/>
      </div>
      <div v-else-if="!$apollo.loading && $store.state.me.role !== 'partner'" class="widgets">
        <component :is="widget.wType" v-for="(widget, index) in widgetList" :key="index" :fields="widget.fields" :filter="filter" :isProtected="widget.isProtected"/>
      </div>
      <div v-else-if="!$apollo.loading && $store.state.me.role === 'partner'" class="widgets--client">
        <component :is="widget.wType" v-for="(widget, index) in widgetList" :key="index" :fields="widget.fields" :filter="filter"/>
      </div>
      <div v-else-if="$store.state.me.role === 'qwadmin'" class="widgets">
        <SplitPercentWidget :fields="{
          class: 'h-1-4',
          items: [
            {
              class: 'text-orange',
              title: 'Исходящие звонки',
              number: '52%'
            },
            {
              class: 'text-orange',
              title: 'Выполнено',
              number: '34/60'
            },
          ]
        }"/>
        <StandartWidget :fields="{
          class: '',
          title: 'Всего новых заявок',
          content: '',
          number: '148',
          link: {
            link: '',
            outside: false
          }
        }"/>
        <StandartWidget :fields="{
          class: '',
          title: 'Переданные заявки',
          content: '',
          number: '38',
          link: {
            link: 'https://mail.ru',
            outside: true
          }
        }"/>
        <StandartWidget :fields="{
          class: 'text-orange',
          title: 'Доля звонков в заявках',
          content: '',
          number: '49%',
          link: {
            link: '',
            outside: false
          }
        }"/>
        <StandartWidget :fields="{
          class: 'text-red',
          title: 'Отказ СБ',
          content: '',
          number: '13%',
          link: {
            link: '',
            outside: false
          }
        }"/>
        <SplitWidget :fields="{
          class: '',
          items: [
            {
              class: 'text-orange',
              title: 'Заведенные проекты',
              number: '25'
            },
            {
              class: 'text-blue',
              title: 'Одобрено',
              number: '12'
            },
          ]
        }"/>
        <TwiceWidget :fields="{
          class: 'text-green',
          title: 'Выдано',
          content: '15 проектов',
          number: '38 080 000 ₽'
        }"/>
        <TwiceFillWidget :fields="{
          class: 'text-green',
          title: 'Заработок',
          number: '47 320 ₽',
          fill_text: 'До повыш. коэф.'
        }"/>
        <FillWidget :fields="{
          class: 'status-fill-50',
          items: [
            {
              title: 'План',
              content: '68 000 000 ₽'
            },
            {
              title: 'Выполнено',
              content: '56 %'
            },
            {
              title: 'Остаток',
              content: '29 920 000 ₽'
            }
          ]
        }"/>
        <CheckWidget :fields="{
          class: 'w-2-4',
          title: 'Средний чек',
          items: [
            {
              title: 'Июнь 2019',
              content: '3 402 000₽'
            },
            {
              title: 'Июнь 2020',
              content: '2 393 000₽'
            }
          ],
          graph: '-1,2%'
        }"/>
        <StandartWidget :fields="{
          class: '',
          title: 'Ушли к конкурентам',
          content: '',
          number: '30%',
          link: {
            link: '',
            outside: false
          }
        }"/>
      </div>
      <div v-if="$store.state.me.role === 'partner'" class="banner">
        <a href="https://www.bankofkazan.ru/business/business-credits/?utm_source=leasing-trade&utm_medium=banner&utm_campaign=credit-ul" target="_blank">
          <label v-if="this.sizeToggle > 900">
            <HochuCredit1800/>
          </label>
          <label v-else><HochuCredit988/></label>
        </a>
      </div>
    </div>
    <!-- <div v-if="$store.state.me.role !== 'partner'" class="main__content">
      <div class="tbl">
        <TableWidget/>
      </div>
    </div> -->
  </main>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
import RefreshIcon from '../components/svg/RefreshIcon'
import CalendarIcon from '../components/svg/CalendarIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import gql from 'graphql-tag'
import SettingsIcon from '@/components/svg/settings'
// Widgets
import ButtonWidget from '../components/widgets/Button'
import StandartWidget from '../components/widgets/Standart'
import AlterStandartWidget from '../components/widgets/AlterStandart'
import CastomWidget from '../components/widgets/Castom'
import FaqWidget from '../components/widgets/Faq'
import CalcWidget from '../components/widgets/Calc'
import SplitWidget from '../components/widgets/Split'
import TwiceWidget from '../components/widgets/Twice'
import FillWidget from '../components/widgets/Fill'
import CheckWidget from '../components/widgets/Check'
import TwiceFillWidget from '../components/widgets/TwiceFill'
import SplitPercentWidget from '../components/widgets/SplitPercent'
import TableWidget from '../components/widgets/Table'
import PhoneIcon from '../components/svg/phone'
import InternetIcon from '../components/svg/InternetIcon'
import DownIcon from '@/components/svg/DownIcon'
import HochuCredit988 from '@/components/svg/HochuCredit988'
import HochuCredit1800 from '@/components/svg/HochuCredit1800'
// dept
import OsDesktop from '@/components/layouts/osDesktop/OsDesktop.vue'
// manager
import ManagerDesktop from '@/components/layouts/managerDesktop/ManagerDesktop.vue'

const _moment = extendMoment(moment)
const Today = _moment()
const Yesterday = _moment().subtract(1, 'days')
const NextWeek = _moment().endOf('week').add(1, 'week')

export default {
  name: 'home',
  components: {
    InternetIcon,
    PhoneIcon,
    CalendarIcon,
    RefreshIcon,
    DatePicker,
    DownIcon,
    HochuCredit988,
    HochuCredit1800,
    SettingsIcon,
    // widgets
    StandartWidget,
    AlterStandartWidget,
    CastomWidget,
    FaqWidget,
    CalcWidget,
    SplitWidget,
    TwiceWidget,
    FillWidget,
    CheckWidget,
    TwiceFillWidget,
    SplitPercentWidget,
    TableWidget,
    ButtonWidget,
    // dept
    OsDesktop,
    // manager
    ManagerDesktop
  },
  created () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  data () {
    return {
      title: 'Рабочий стол',
      openPeriod: false,
      widgetList: [],
      sortFilter: {},
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      },
      filterDropName: [],
      filterDropGroup: [],
      filter: {
        responsible_user_id: null,
        group_id: null
      },
      sizeToggle: 0,
      rerenderKey: 0
    }
  },
  watch: {
    '$store.state.data.trades.sortDate' () {
      if (this.$store.state.data.trades.sortDate) {
        this.$store.state.data.trades.sort2 = 'period'
      } else {
        this.$store.state.data.trades.sort2 = null
      }
      this.openPeriod = false
    },
    '$store.state.data.widgets.clientColumns': {
      handler () {
        this.forceRerender()
      },
      deep: true
    }
    // '$store.state.data.widgets.columns': {
    //   handler () {
    //     this.forceRerender()
    //   },
    //   deep: true
    // }
  },
  computed: {
    getSettings () {
      return this.$store.state.me.role !== 'partner' ? this.$store.state.data.widgets.columns : this.$store.state.data.widgets.clientColumns
    },
    getPeriodFilter () {
      switch (this.$store.state.data.trades.sort2) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Yesterday).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('week').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'next_week':
          return {
            start: _moment(NextWeek).startOf('week').format('YYYY-MM-DD'),
            end: _moment(NextWeek).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'after_next_week':
          return {
            start: _moment(NextWeek).endOf('week').format('YYYY-MM-DD')
          }
        case 'expired':
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.trades.sortDate[0]).startOf('day').format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.trades.sortDate[1]).endOf('day').format('YYYY-MM-DD')
          }
        default:
          return {
            end: _moment(Today).startOf('day').format('YYYY-MM-DD')
          }
      }
    },
    getApolloFilter () {
      let filter = {}
      filter.responsible_user_id = this.filter.responsible_user_id
      filter.group_id = this.filter.group_id
      return filter
    },
    extendedGroup () {
      const group = [...this.filterDropGroup]
      group.push({
        name: 'Филиалы',
        id: [
          '57a91d60-1c2e-11e2-8296-00304866b623',
          'dee2beee-4aac-11de-a104-00304866b623',
          '74cb0892-681c-11e9-bb4f-bcee7be31495',
          '1814a48e-afa1-11ea-80bf-00259047d1e4',
          'e5786ff6-4aac-11de-a104-00304866b623',
          '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
          'e578711c-4aac-11de-a104-00304866b623',
          'e57870a3-4aac-11de-a104-00304866b623',
          '9493c05d-4e93-11de-866b-001b38e49421',
          'e5787009-4aac-11de-a104-00304866b623',
          'dc096dea-feb3-11ec-80c9-00259047d1e4',
          '936c25c0-835c-11ec-80c6-00259047d1e4']
      })
      group.push({
        name: 'Филиалы Татарстан',
        id: ['57a91d60-1c2e-11e2-8296-00304866b623', 'dee2beee-4aac-11de-a104-00304866b623', 'e5787009-4aac-11de-a104-00304866b623', '936c25c0-835c-11ec-80c6-00259047d1e4']
      })
      group.push({
        name: 'Филиалы не Татарстан',
        id: [
          '74cb0892-681c-11e9-bb4f-bcee7be31495',
          '1814a48e-afa1-11ea-80bf-00259047d1e4',
          'e5786ff6-4aac-11de-a104-00304866b623',
          '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
          'e578711c-4aac-11de-a104-00304866b623',
          'e57870a3-4aac-11de-a104-00304866b623',
          '9493c05d-4e93-11de-866b-001b38e49421',
          'dc096dea-feb3-11ec-80c9-00259047d1e4']
      })
      group.push({
        name: 'Казань офис продаж',
        id: ['245be5c7-26b6-11e9-bb4f-bcee7be31495', '32a431c7-26b6-11e9-bb4f-bcee7be31495']
      })
      group.push({
        name: 'Дивизион РТ',
        id: ['dee2beee-4aac-11de-a104-00304866b623', 'e5787009-4aac-11de-a104-00304866b623', '8eed7703-7a08-11ee-80c9-00259047d1e4']
      })
      group.push({
        name: 'Дивизион РБ',
        id: ['9493c05d-4e93-11de-866b-001b38e49421', 'e5786ff6-4aac-11de-a104-00304866b623', '3ae891c5-719c-11ee-80c9-00259047d1e4']
      })
      return group
    }
  },
  apollo: {
    widgetList () {
      return {
        query: gql`query($sort: PeriodFilter, $filter: JSON, $settings: JSON, $rerenderKey: Int) {
          getWidget(sort: $sort, filter: $filter, settings: $settings, rerenderKey: $rerenderKey)
        }`,
        variables () {
          return {
            sort: this.getPeriodFilter,
            filter: this.getApolloFilter,
            settings: this.getSettings,
            rerenderKey: this.rerenderKey
          }
        },
        update: data => {
          if (!this.filter.responsible_user_id) {
            this.filterDropName = data.getWidget.userList
          }
          if (!this.filter.group_id) {
            this.filterDropGroup = data.getWidget.groupList
          }
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data.getWidget.widget
        }
      }
    }
  },
  methods: {
    forceRerender () {
      this.rerenderKey++
    },
    onResize () {
      this.sizeToggle = document.documentElement.clientWidth
    },
    setSortType (type) {
      if (this.$store.state.data.trades.sort2 === type) {
        this.$store.state.data.trades.sort2 = null
      } else {
        this.$store.state.data.trades.sort2 = type
      }
    },
    onUserChange (e) {
      if (e) {
        this.filter.responsible_user_id = e.value
      } else {
        this.filter.responsible_user_id = null
      }
    },
    onGroupChange (e) {
      if (e) {
        this.filter.group_id = e.value
      } else {
        this.filter.group_id = null
      }
    },
    switchColumnModal () {
      this.$store.state.data.widgets.columnModal = !this.$store.state.data.widgets.columnModal
    }
  }
}
</script>

<style lang="stylus" scoped>
.widgets {
  @keyframes loading {
    0% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0 50%
    }
  }
  > .sceleton {
    position: relative
    height: 140px
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%)
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite
  }
}
</style>

<style lang="stylus">

.btn--size
  height 93px

.toper__item
  display: flex
  flex-direction: row
  justify-content: center

  +below(1150px)
    &.right
      margin-top -40px

.widgets
  display grid
  grid-template-columns repeat(5, 1fr)
  grid-gap 15px
  padding 0 20px
  margin-bottom 30px

  +below(1100px)
    grid-template-columns repeat(4, 1fr)

  +below(768px)
    grid-template-columns repeat(3, 1fr)

  +below(480px)
    grid-template-columns repeat(2, 1fr)

.widgets--client
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-column-gap 15px
  grid-row-gap 30px
  margin auto
  max-width 1000px
  padding 0 20px
  margin-bottom 30px

  // +below(1100px)
  //   grid-template-columns repeat(4, 1fr)

  +below(768px)
    grid-template-columns repeat(2, 1fr)

  // +below(480px)
  //   grid-template-columns repeat(2, 1fr)

.widgets__item
  background $white
  border 1px solid $darkgray
  border-radius 10px
  position relative
  display flex
  flex-direction column
  transition all 0.3s
  z-index 0

  &.widget_fill
    background $lightdark
    color $white
    padding 10px 15px
    overflow hidden

    +below(480px)
      grid-column 1 / -1
      grid-row 4

  &:hover
    box-shadow alpha($dark, 20%) 0 20px 40px
    border-color transparent
    z-index 1

    &.widget_fill
      border-color $darkgray

// special classes
.text-red
  .widgets__content
    color $red

.text-orange
  .widgets__content
    color $orange
  .castom_widgets__content > .castom_widget__number
    color $orange

.text-green
  .widgets__content
    color #219653

.text-blue
  .widgets__content
    color #2D9CDB

.text-gray
  .widgets__content
    color #4E555A

.w-2-4
  grid-column 2 / 4

  +below(1100px)
    grid-column 3 / 5

  +below(768px)
    grid-column 1 / 3

  +below(480px)
    grid-column 1

.h-1-3
  grid-row 1 / 3

  +below(768px)
    grid-row 1

.h-1-4
  grid-row 1 / 4

  +below(768px)
    grid-row 1

.widgets__table
  @extends .tbl__container

  &__field
    display flex
    align-items center

    i
      display flex
      align-items center
      justify-content center
      margin-right 14px

    span
      @extends .big

  table
    box-shadow 0 7px 30px rgba($black, 0.16)
    border-radius 10px

    th
      font-size: 13px
      line-height: 16px
      color: $c7A

  .big
    font-size: 15px
    line-height: 18px

.banner
  display flex
  align-items center
  justify-content center
  margin 200px auto 0 auto
  max-width 1800px
  overflow hidden
  padding 0 20px

  > a > label
    max-width 960px
    display flex
    align-items center
    justify-content center
    max-height 244px
    cursor pointer

    > svg
      height 127px

  // +below(1920px)
  //   max-width 800px
  //   > label
  //     max-width 988px

  +below(768px)
    margin-top 0.5vh

  +below(540px)
    margin-top 0
</style>
