<template>
  <button class="widgets__item calc_widget btn--size" @click="() => { this.$store.state.calcModal = true }">Рассчитать лизинг</button>
  <!-- <div class="widgets__item widget_fill">
    <div :class="`fill_status ` + fields.class" :style="fields.style"><fillReady/></div>
    <div class="widget_fill__content">
      <ul>
        <li v-for="(item, index) in fields.items" :key="index"><span>{{ item.title }}</span><strong>{{ item.content }}</strong></li>
      </ul>
    </div>
  </div> -->
</template>

<script>
export default {
  props: [
    'fields',
    'filter'
  ],
  methods: {
    resolvePath (item) {
      this.setEmptyTradesFilters()
      this.setEmptyTasksFilters()
      this.setEmptyContractsFilters()
      this.$store.state.buyoutModalToggle = true
      if (item.filter) Object.assign(this.$store.state.data[item.route].filter, item.filter)
      if (item.checked) this.$store.state.data[item.route].checked = true
      if (this.filter.group_id) this.$store.state.data[item.route].filter.group_id = this.filter.group_id
      if (this.filter.responsible_user_id) this.$store.state.data[item.route].filter.responsible_user_id = this.filter.responsible_user_id
      this.$router.push(item.route)
    },
    setEmptyTradesFilters () {
      this.$store.state.data.trades.filter.status = null
      this.$store.state.data.trades.filter.responsible_user_id = null
      this.$store.state.data.trades.filter.group_id = []
      this.$store.state.data.trades.filter.tags = []
      this.$store.state.data.trades.filter.created_by = null
      this.$store.state.data.trades.checked = false
    },
    setEmptyContractsFilters () {
      this.$store.state.data.contracts.filter.status = null
      this.$store.state.data.contracts.filter.responsible_user_id = null
      this.$store.state.data.contracts.filter.group_id = []
      this.$store.state.data.contracts.filter.tags = []
      this.$store.state.data.contracts.filter.signed = null
      this.$store.state.data.contracts.filter.unloaded = null
      this.$store.state.data.contracts.filter.advance = null
    },
    setEmptyTasksFilters () {
      this.$store.state.data.tasks.filter.responsible_user_id = null
      this.$store.state.data.tasks.filter.group_id = []
      this.$store.state.data.tasks.filter.status = null
    }
  }
}
</script>

<style lang="stylus">

.calc_widget {
  margin 0
  padding 5px 30px
  position relative
  align-items center
  justify-content center
  border 1px solid #212529 !important
  font-size 1em
  min-height 40px
  border-radius 5px
  background #212529 !important
  font-family 'Montserrat-Regular'
  color #fff
  line-height normal
  cursor pointer
  transition all 0.3s
  z-index 1
  padding 9px 20px
  border-radius 10px

  &:hover {
    color #fff
    background-color #303440 !important
    border-color #303440 !important
  }
}

</style>
