<template>
  <div class="widgets__item widget_fill">
    <div :class="`fill_status ` + fields.class" :style="fields.style"><fillReady/></div>
    <div class="widget_fill__content">
      <ul>
        <li v-for="(item, index) in fields.items" :key="index"><span>{{ item.title }}</span><strong>{{ item.content }}</strong></li>
      </ul>
    </div>
  </div>
</template>

<script>
import fillReady from '@/components/svg/fillReady'

export default {
  props: [
    'fields'
  ],
  components: {
    fillReady
  }
}
</script>

<style lang="stylus">

.fill_status
  absolute left top
  width 100%
  height 100%
  display flex
  align-items center
  justify-content center
  background $dark
  z-index 1

  svg
    width 100%
    height auto
    max-width 70px
    opacity 0.1
    position relative
    fill $white
    z-index 1

  &::after
    content ''
    absolute left top
    width var(--progress)
    height 100%
    background $green
    z-index 2

.widget_fill__content
  width 100%
  position relative
  z-index 2

.widget_fill
  display flex
  align-items center
  justify-content center

  ul
    padding 0
    margin 0
    width 100%

    li
      display flex
      align-items center
      justify-content space-between
      flex-wrap wrap

</style>
