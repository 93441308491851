<template>
  <div :class="`widgets__item widget_split ` + fields.class">
    <div v-for="(item, index) in fields.items" :key="index" :class="`widget_split__item ` + item.class">
      <div class="widgets__title" v-if="item.title">{{ item.title }}</div>
      <div class="widgets__content" :class="{ 'widgets__content--protected': isProtected && protectedToggle }" @click="resolvePath(item)" v-if="item.number">
        <span v-if="isProtected && protectedToggle">****</span>
        <span v-else>{{ prettifyPrice(item.number) }} {{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Object,
    filter: Object,
    isProtected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      protectedToggle: true,
      PROTECT_TIMEOUT_MS: 10_000
    }
  },
  methods: {
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    resolvePath (item) {
      if (this.isProtected && this.protectedToggle) {
        this.protectedToggle = false

        setTimeout(() => {
          this.protectedToggle = true
        }, this.PROTECT_TIMEOUT_MS)

        return
      }

      this.setEmptyTradesFilters()
      this.setEmptyTasksFilters()
      this.setEmptyContractsFilters()
      if (!item.route) return

      if (item.filter) Object.assign(this.$store.state.data[item.route].filter, item.filter)
      if (item.checked) this.$store.state.data[item.route].checked = true
      if (this.filter.group_id) this.$store.state.data[item.route].filter.group_id = this.filter.group_id
      if (this.filter.responsible_user_id) this.$store.state.data[item.route].filter.responsible_user_id = this.filter.responsible_user_id
      if (this.filter.updatedAt) this.$store.state.data[item.route].filter.updatedAt = this.filter.updatedAt
      if (item.filter.status === 'expired') {
        this.$store.state.data[item.route].filter.complete_till_at = { end: new Date().toDateString() }
      }
      this.$router.push(item.route)
    },
    setEmptyTradesFilters () {
      this.$store.state.data.trades.filter.status = null
      this.$store.state.data.trades.filter.responsible_user_id = null
      this.$store.state.data.trades.filter.updatedAt = null
      this.$store.state.data.trades.filter.group_id = []
      this.$store.state.data.trades.filter.tags = []
      this.$store.state.data.trades.filter.created_by = null
      this.$store.state.data.trades.filter.hasContracts = null
      this.$store.state.data.trades.checked = false
    },
    setEmptyContractsFilters () {
      this.$store.state.data.contracts.filter.status = null
      this.$store.state.data.contracts.filter.responsible_user_id = null
      this.$store.state.data.contracts.filter.group_id = []
      this.$store.state.data.contracts.filter.tags = []
      this.$store.state.data.contracts.filter.signed = null
      this.$store.state.data.contracts.filter.unloaded = null
      this.$store.state.data.contracts.filter.advance = null
    },
    setEmptyTasksFilters () {
      this.$store.state.data.tasks.filter.responsible_user_id = null
      this.$store.state.data.tasks.filter.group_id = []
      this.$store.state.data.tasks.filter.status = null
    }
  }
}
</script>

<style lang="stylus">

.widget_split
  .widgets__title
    padding 0
    border none

  .widgets__content
    padding 0
    font-size 2em
    line-height normal
    font-family font_bold
    overflow-wrap anywhere

    +below(1920px)
      font-size 1.5vw

    +below(1100px)
      font-size 1.2em

    +below(768px)
      font-size 1.1em

    +below(480px)
      font-size 1em

    &:hover
      text-decoration: underline
      cursor: pointer

  +below(480px)
    grid-column 1 / -1
    flex-direction row !important

.widget_split__item
  display flex
  flex-direction column
  border-bottom 1px solid $darkgray
  padding 10px 15px
  flex 1

  &:last-child
    border none

  +below(480px)
    border-bottom none !important

    &:first-child
      border-right 1px solid $darkgray !important

</style>
