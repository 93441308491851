<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      compactMode
      :pagination-options="{
        enabled: true,
        perPage: 2,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Вывести',
        ofLabel: 'из',
        pageLabel: 'страниц',
        allLabel: 'Все'
      }"
    >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'outgoing_calls'">
        <i><PhoneOut/></i> {{props.row.outgoing_calls}}
      </span>
      <span v-else-if="props.column.field == 'transferred'">
        <i><MailOut/></i> {{props.row.transferred}}
      </span>
      <span v-else-if="props.column.field == 'created_projects'">
        <i><ConfirmIcon/></i> {{props.row.created_projects}}
      </span>
      <span v-else-if="props.column.field == 'issued_projects'">
        <i><FolderIcon/></i> {{props.row.issued_projects}}
      </span>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
    <div slot="emptystate">
      Данных нет
    </div>
    </vue-good-table>
  </div>
</template>

<script>
import PhoneOut from '@/components/svg/PhoneOut'
import MailOut from '@/components/svg/MailOut'
import ConfirmIcon from '@/components/svg/ConfirmIcon'
import FolderIcon from '@/components/svg/FolderIcon'

export default {
  name: 'widget-table',
  components: {
    PhoneOut,
    MailOut,
    ConfirmIcon,
    FolderIcon
  },
  data () {
    return {
      columns: [
        {
          label: 'Сотрудник',
          field: 'workers'
        },
        {
          label: 'Исходящие звонки',
          field: 'outgoing_calls',
          type: 'number'
        },
        {
          label: 'Переданные заявки',
          field: 'transferred',
          type: 'number'
        },
        {
          label: 'Заведено проектов',
          field: 'created_projects',
          type: 'number'
        },
        {
          label: 'Выдано проектов',
          field: 'issued_projects',
          type: 'number'
        },
        {
          label: 'CR',
          field: 'cr',
          type: 'percentage'
        }
      ],
      rows: [
        {
          id: 1,
          workers: 'Миннуллин А. Ф.',
          outgoing_calls: 1478,
          transferred: 245,
          created_projects: 395,
          issued_projects: 23,
          cr: 0.28
        },
        {
          id: 2,
          workers: 'Иванов И. И.',
          outgoing_calls: 2500,
          transferred: 241,
          created_projects: 480,
          issued_projects: 24,
          cr: 0.29
        },
        {
          id: 3,
          workers: 'Захарова М. Н.',
          outgoing_calls: 3400,
          transferred: 350,
          created_projects: 546,
          issued_projects: 35,
          cr: 0.35
        }
      ]
    }
  }
}
</script>
