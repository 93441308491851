<template>
  <div class="manager-desktop-menu__container" :class="{ active: show }" :key="refreshKey" v-if="leadId">
    <div @click="closeMenu" @click.right="closeMenu" class="manager-desktop-menu__bg"></div>
    <div class="manager-desktop-menu" v-if="lead">
      <div class="manager-desktop-menu__header">
        {{ lead.name }}
      </div>
      <div class="manager-desktop-menu__body">
        <div class="workspace_left__row accordion">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: leadDropdown,
              }"
              @click="leadDropdown = !leadDropdown"
            >
              <span>Сделка</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="leadDropdown">
              <ul class="tc">
                <li>
                  <ul class="tc__list">
                    <li>
                      <strong class="tc__list__title">Наименование</strong>
                      <span>{{ lead.name }}</span>
                    </li>
                    <li>
                      <strong class="tc__list__title">Статус</strong>
                      <span class="tag" :class="getStatusColor(lead.status)">{{ lead.status }}</span>
                    </li>
                    <li>
                      <strong class="tc__list__title">Стоимость</strong>
                      <span>{{ prettifyPrice(lead.calcPriceSum) }}</span>
                    </li>
                    <li v-if="lead.serviceComment">
                      <strong class="tc__list__title">Комментарий по сделке</strong>
                    </li>
                    <li v-if="lead.serviceComment">
                      <div>{{ lead.serviceComment.text }}</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="workspace_left__row accordion">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: calcDropdown,
              }"
              @click="calcDropdown = !calcDropdown"
            >
              <span>Предмет лизинга</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="calcDropdown">
              <ul class="tc">
                <li>
                  <div class="tc__check"></div>
                  <ul class="tc__list">
                    <li
                      v-for="(calculator, index) in lead.calculators"
                      :key="index"
                      class="tc__list--grid"
                    >
                      <span class="tc__list__title">{{
                        calculator.contract || calculator.name || "Без наименования"
                      }}</span>
                      <span class="tc__list__subtitle">{{
                        prettifyPrice(calculator.price)
                      }}</span>
                      <div style="width: 100%; margin: 15px 0">
                        <vue-good-table style="min-width: 100%" max-height="300px" :columns="paymentColumns" :pagination-options="{enabled: false}" :rows="calculator.graph.map((pay, i) => {pay.number = i + 1; return pay})" compactMode>
                          <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'number'">{{ props.row.number }}</span>
                            <span v-if="props.column.field === 'date'">{{ new Date(props.row.date).toLocaleDateString() }}</span>
                            <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.summ) }}</span>
                            <!-- <span v-if="props.column.field === 'paid'">{{ prettifyPrice(props.row.advPayment) }}</span> -->
                          </template>
                          <div slot="emptystate">Данных нет</div>
                        </vue-good-table>
                      </div>
                      <div class="btn" @click="downloadCalc(calculator.id)">PDF</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="lead.company" class="workspace_left__row accordion">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: companyDropdown,
              }"
              @click="companyDropdown = !companyDropdown"
            >
              <span>Клиент</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="companyDropdown">
              <ul class="tc">
                <li>
                  <ul class="tc__list">
                    <li>
                      <strong class="tc__list__title">Наименование</strong>
                      <span>{{ lead.company.name }}</span>
                    </li>
                    <li>
                      <strong class="tc__list__title">ИНН</strong>
                      <span>{{ lead.company.inn }}</span>
                    </li>
                    <li v-if="lead.company.email">
                      <strong class="tc__list__title">Email</strong>
                      <a :href="`mailto:${lead.company.email}`">{{ lead.company.email }}</a>
                    </li>
                    <li v-if="lead.company.phone">
                      <strong class="tc__list__title">Телефон</strong>
                      <a :href="`tel:${lead.company.phone[0]}`" class="tc__list__subtitle">{{
                        lead.company.phone[0]
                      }}</a>
                      <i v-if="!callTimeout" @click="callPhone(lead.company.phone[0])">
                        <PhoneOutIcon />
                      </i>
                      <i v-else style="cursor: wait">
                        X
                      </i>
                    </li>
                    <li v-if="lead.company.sign_date">
                      <strong class="tc__list__title">Дата согласия</strong>
                      <span>{{ new Date(lead.company.sign_date).toLocaleDateString() }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="workspace_left__row accordion" v-if="lead.contracts && lead.contracts.length && lead.contracts[0]">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: contractDropdown,
              }"
              @click="contractDropdown = !contractDropdown"
            >
              <span>Договоры</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="contractDropdown">
              <ul class="tc">
                <li>
                  <div class="tc__check"></div>
                  <ul class="tc__list">
                    <li
                      v-for="(contract, index) in lead.contracts"
                      :key="index"
                    >
                      <span class="tc__list__title">
                        <router-link
                          :to="{
                            name: 'contract',
                            params: { id: contract.id },
                          }"
                          class="tc__list__title"
                          >{{ contract.name }}</router-link
                        >
                      </span>
                      <span class="tag" :class="getStatusColor(contract.status)">{{ contract.status }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="workspace_left__row accordion">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: contactDropdown,
              }"
              @click="contactDropdown = !contactDropdown"
            >
              <span>Контакты</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="contactDropdown">
              <ul class="tc">
                <li
                  v-for="contact in lead.company.contacts"
                  :key="contact._id"
                >
                  <ul class="tc__list">
                    <li>
                      <router-link
                        :to="{
                          name: 'contact',
                          params: { id: contact._id },
                        }"
                        class="tc__list__title"
                        >{{ contact.name }}</router-link
                      >
                      <span
                        class="tc__list__subtitle"
                        v-if="contact.post"
                        >{{ contact.post }}</span
                      >
                    </li>
                    <li v-if="contact.phone.length">
                      <span class="tc__list__title">Телефон</span>
                      <a :href="`tel:${contact.phone[0]}`" class="tc__list__subtitle">{{
                        contact.phone[0]
                      }}</a>
                      <i v-if="!callTimeout" @click="callPhone(contact.phone[0])">
                        <PhoneOutIcon />
                      </i>
                      <i v-else style="cursor: wait">
                        X
                      </i>
                    </li>
                    <li v-if="contact.email">
                      <span class="tc__list__title">Email</span>
                      <a :href="`mailto:${contact.email.split(';').join(', ')}`" class="tc__list__subtitle">{{
                        contact.email.split(";").join(", ")
                      }}</a>
                      <i></i>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="workspace_left__row accordion">
          <div class="workspace_body__item accordion__item">
            <h3
              :class="{
                trade_content__title: true,
                active: fileDropdown,
              }"
              @click="fileDropdown = !fileDropdown"
            >
              <span>Документы</span>
              <ArrowDown />
            </h3>
            <div class="accordion__content" v-if="fileDropdown">
              <ul class="tc">
                <li
                  v-for="(category, index) in groupBy(
                      [
                        ...lead.company.files,
                        ...lead.files,
                        lead.contracts && lead.contracts.length ? lead.contracts.map(elem => elem.files) : []
                      ].flat(Infinity),
                      'view'
                    )"
                    :key="index"
                >
                  <div class="tc__check"></div>
                  <ul class="tc__list">
                    <li><strong>{{ category.title }}</strong></li>
                    <li
                      :key="index"
                      v-for="(doc, index) in category.items"
                    >
                      <span class="tc__list__title">{{ doc.name }}</span>
                      <a class="tc__list__subtitle" :href="'https://' + doc.path" target="_blank" download>Скачать ({{ doc.ext }})</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="manager-desktop-menu">
      <div class="sceleton"></div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import vue2Dropzone from 'vue2-dropzone'
// import PaperClip from '@/components/svg/PaperClip'
// import PlusIcon from '@/components/svg/plus.vue'
// import SettingsIcon from '@/components/svg/settings.vue'
// import DeleteIcon from '@/components/svg/DeleteIcon.vue'
// import GraphQLHelper from '@/helpers/GraphQLHelper'
import _ from 'lodash'
import LEAD from '@/graphql/queries/LeadInMenu.gql'
import ArrowDown from '@/components/svg/arrow_down'
import PhoneOutIcon from '@/components/svg/new/PhoneOutIcon'

export default {
  name: 'ManagerDesktopMenu',
  components: {
    ArrowDown,
    PhoneOutIcon
  },
  props: {
    leadId: String
  },
  data () {
    return {
      refreshKey: 0,
      lead: null,
      leadDropdown: true,
      calcDropdown: false,
      companyDropdown: false,
      contactDropdown: false,
      contractDropdown: false,
      fileDropdown: false,
      callTimeout: false,
      paymentColumns: [
        {
          sortable: false,
          label: '№',
          field: 'number'
        },
        {
          sortable: false,
          label: 'Дата',
          field: 'date'
        },
        {
          sortable: false,
          label: 'Платеж',
          field: 'plan'
        }
      ]
    }
  },
  created () {
    this.loadData()
  },
  beforeDestroy () {
    this.lead = null
  },
  computed: {
    show () {
      return this.$store.state.showManagerDesktopMenu
    }
  },
  methods: {
    downloadCalc (id) {
      window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=${id}`, '_blank')
    },
    groupBy (array, string) {
      let group = _.groupBy(array, string)
      return Object.keys(group).map((key) => ({
        title: key,
        items: group[key]
      }))
    },
    prettifyPrice (price) {
      if (price) {
        return Number(price).toLocaleString()
      }
      return 0
    },
    getStatusColor (status) {
      switch (status) {
        case 'Не обработано':
          return 'tag--blue'
        case 'Переговоры':
          return 'tag--purple'
        case 'Сбор документов':
          return 'tag--yellow'
        case 'Создание':
          return 'tag--dark-blue'
        case 'Рассмотрение':
          return 'tag--orange'
        case 'Одобрен':
          return 'tag--green'
        case 'Отказ служб':
          return 'tag--red'
      }
    },
    async loadDirectories () {
      await Promise.all([
        this.$store.dispatch('allDirectories', this)
      ])
    },
    closeMenu () {
      this.$store.state.showManagerDesktopMenu = false
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    async loadData () {
      await this.$apollo
        .mutate({
          mutation: LEAD,
          variables: {
            id: this.leadId
          },
          update: (store, { data }) => {
            if (data) {
              const lead = data.Lead
              lead.status = this.$store.state.leadStatusListClear
                .find(leadStatus => {
                  if (leadStatus.value === lead.status) return leadStatus
                })
                .title
              this.lead = lead
            }
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .sceleton
    position: relative
    display: inline-block
    height: 100%
    width: 100%
    margin: 0 10.5px 5px
    border-radius: 10px
    background: linear-gradient(100deg, #ff8000 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%
    animation: loading 1.2s ease-in-out infinite;

    &--block
      width: 100%
      height: 250px

  .avatar {
    display flex
    flex-direction column

    .dz-preview {
      display none
    }
  }
</style>

<style lang="stylus">
.manager-desktop {
  &-menu__container {
    max-height 100vh
    &.active {
      .manager-desktop-menu__bg {
        display block
      }

      .manager-desktop-menu {
        transform translateX(0)
      }
    }
  }

  &-menu__bg {
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 999
    background-color rgba($black, 0.5)
    display none
  }

  &-menu {
    position fixed
    top 0
    right 0
    bottom 0
    z-index 1000
    transition transform 0.3s
    transform translateX(100%)
    display flex
    flex-direction column
    width 100%
    max-width 600px
    height 100vh
    max-height 100vh
    background-color $white
    overflow-y scroll
    overflow-x hidden

    &__header {
      font-weight: 500;
      font-size 1.2em
      line-height: 21px;
      padding 27px 30px 32px
      border-bottom 1px solid $pinky
    }

    &__body {
      display flex
      flex-direction column
      padding 10px 35px
      flex 1

      &__add, &__change {
        display flex
        flex-direction column
        align-items center
        overflow-y auto
      }

      &__change {
        background-color $gray
      }

      &__item {
        &:first-child {
          margin-top 10px
        }
        &:not(:first-child) {
          margin-top 10px
        }
        display inline-block
        width 80%
        min-height 60px

        &__textarea {
          resize none
        }

        > span {
          display inline-block
          color gray
          margin-bottom 5px
        }
      }
    }

    &__list {
      flex 1
      margin 0
      padding 0
      overflow-y auto
      scrollbar-width: thin;
    }

    &__step {
      display flex
      align-items center
      padding 15px 30px
      font-size: 1.1em
      line-height: 24px;
      border-bottom 1px solid $pinky
      transition 0.2s

      &:hover {
        background-color $gray
      }

      &.manager-desktop-add__btn {
        padding 18px 30px
        text-align center
        justify-content center
        gap 5px
        color $orange

        svg {
          width 20px
          height 20px

          path {
            stroke $orange
          }
        }
      }

      &-title {
        flex-grow 1
      }

      &-actions {
        display flex
        gap 5px
        margin-left auto

        span {
          display flex
          align-items center
          justify-content center
          width 30px
          min-width 30px
          height 30px
          border-radius: 10px;
          padding 7px
          transition 0.3s
          cursor pointer

          svg {
            width 16px
            height 16px

            path[stroke] {
              stroke $darkgray
            }

            path[fill] {
              fill $darkgray
            }
          }

          &.active,
          &:hover {
            color $white
            background-color $orange

            svg {
              path[stroke] {
                stroke $white
              }

              path[fill] {
                fill $white
              }
            }
          }
        }

        .manager-desktop__elem-delete {
          &.active,
          &:hover {
            background-color $red
          }
        }
      }
    }

    &__bottom {
      display grid
      grid-template-columns repeat(2, 1fr)
      padding 30px
      gap 16px
      border-top 1px solid $pinky

      button {
        font-size: 15px;
        line-height: 22px;
        text-transform uppercase
      }
    }
  }
}

.tc__list--grid {
  display flex
  flex-wrap wrap
}
</style>
