<template>
  <div :class="`widgets__item widget_standart ` + fields.class">
    <div class="widgets__title" v-if="fields.title">{{ fields.title }}</div>
    <div class="widgets__content" v-if="fields.content || fields.number">
      <div class="widget__text" v-if="fields.content">{{ fields.content }}</div>
      <div class="widget__number" v-if="fields.number">{{ fields.number }}</div>
      <div class="widget__link" v-if="fields.link.link">
        <a :href="fields.link.link" v-if="fields.link.outside === true" target="_blank"><ExternalIcon/></a>
        <router-link v-else :to="fields.link.link"><ExternalIcon/></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalIcon from '@/components/svg/ExternalIcon'

export default {
  props: [
    'fields'
  ],
  components: {
    ExternalIcon
  }
}
</script>

<style lang="stylus">

.widgets__title
  padding 10px 15px
  font-size 1.1em
  text-align center
  font-family font_bold
  border-bottom 1px solid $darkgray

.widgets__content
  flex 1
  display flex
  align-items center
  justify-content center
  padding 15px
  position relative
  text-align center

.widget__number
  font-size 2em
  font-family font_bold

@media only screen and (max-width: 1400px) {
  .widget__number {
    font-size 1.3em
    font-family font_bold
  }
}

.widget__link
  absolute right 10px bottom 10px

  a
    background $gray
    display flex
    align-items center
    justify-content center
    padding 6px
    border-radius 5px

    svg
      path
        transition stroke 0.3s

    &:hover
      background $orange

      svg
        path
          stroke $white

</style>
