var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.leadId)?_c('div',{key:_vm.refreshKey,staticClass:"manager-desktop-menu__container",class:{ active: _vm.show }},[_c('div',{staticClass:"manager-desktop-menu__bg",on:{"click":_vm.closeMenu,"contextmenu":function($event){return _vm.closeMenu.apply(null, arguments)}}}),(_vm.lead)?_c('div',{staticClass:"manager-desktop-menu"},[_c('div',{staticClass:"manager-desktop-menu__header"},[_vm._v(" "+_vm._s(_vm.lead.name)+" ")]),_c('div',{staticClass:"manager-desktop-menu__body"},[_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.leadDropdown,
            },on:{"click":function($event){_vm.leadDropdown = !_vm.leadDropdown}}},[_c('span',[_vm._v("Сделка")]),_c('ArrowDown')],1),(_vm.leadDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},[_c('li',[_c('ul',{staticClass:"tc__list"},[_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Наименование")]),_c('span',[_vm._v(_vm._s(_vm.lead.name))])]),_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Статус")]),_c('span',{staticClass:"tag",class:_vm.getStatusColor(_vm.lead.status)},[_vm._v(_vm._s(_vm.lead.status))])]),_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Стоимость")]),_c('span',[_vm._v(_vm._s(_vm.prettifyPrice(_vm.lead.calcPriceSum)))])]),(_vm.lead.serviceComment)?_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Комментарий по сделке")])]):_vm._e(),(_vm.lead.serviceComment)?_c('li',[_c('div',[_vm._v(_vm._s(_vm.lead.serviceComment.text))])]):_vm._e()])])])]):_vm._e()])]),_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.calcDropdown,
            },on:{"click":function($event){_vm.calcDropdown = !_vm.calcDropdown}}},[_c('span',[_vm._v("Предмет лизинга")]),_c('ArrowDown')],1),(_vm.calcDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},[_c('li',[_c('div',{staticClass:"tc__check"}),_c('ul',{staticClass:"tc__list"},_vm._l((_vm.lead.calculators),function(calculator,index){return _c('li',{key:index,staticClass:"tc__list--grid"},[_c('span',{staticClass:"tc__list__title"},[_vm._v(_vm._s(calculator.contract || calculator.name || "Без наименования"))]),_c('span',{staticClass:"tc__list__subtitle"},[_vm._v(_vm._s(_vm.prettifyPrice(calculator.price)))]),_c('div',{staticStyle:{"width":"100%","margin":"15px 0"}},[_c('vue-good-table',{staticStyle:{"min-width":"100%"},attrs:{"max-height":"300px","columns":_vm.paymentColumns,"pagination-options":{enabled: false},"rows":calculator.graph.map(function (pay, i) {pay.number = i + 1; return pay}),"compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'number')?_c('span',[_vm._v(_vm._s(props.row.number))]):_vm._e(),(props.column.field === 'date')?_c('span',[_vm._v(_vm._s(new Date(props.row.date).toLocaleDateString()))]):_vm._e(),(props.column.field === 'plan')?_c('span',[_vm._v(_vm._s(_vm.prettifyPrice(props.row.summ)))]):_vm._e()]}}],null,true)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Данных нет")])])],1),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.downloadCalc(calculator.id)}}},[_vm._v("PDF")])])}),0)])])]):_vm._e()])]),(_vm.lead.company)?_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.companyDropdown,
            },on:{"click":function($event){_vm.companyDropdown = !_vm.companyDropdown}}},[_c('span',[_vm._v("Клиент")]),_c('ArrowDown')],1),(_vm.companyDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},[_c('li',[_c('ul',{staticClass:"tc__list"},[_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Наименование")]),_c('span',[_vm._v(_vm._s(_vm.lead.company.name))])]),_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("ИНН")]),_c('span',[_vm._v(_vm._s(_vm.lead.company.inn))])]),(_vm.lead.company.email)?_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Email")]),_c('a',{attrs:{"href":("mailto:" + (_vm.lead.company.email))}},[_vm._v(_vm._s(_vm.lead.company.email))])]):_vm._e(),(_vm.lead.company.phone)?_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Телефон")]),_c('a',{staticClass:"tc__list__subtitle",attrs:{"href":("tel:" + (_vm.lead.company.phone[0]))}},[_vm._v(_vm._s(_vm.lead.company.phone[0]))]),(!_vm.callTimeout)?_c('i',{on:{"click":function($event){return _vm.callPhone(_vm.lead.company.phone[0])}}},[_c('PhoneOutIcon')],1):_c('i',{staticStyle:{"cursor":"wait"}},[_vm._v(" X ")])]):_vm._e(),(_vm.lead.company.sign_date)?_c('li',[_c('strong',{staticClass:"tc__list__title"},[_vm._v("Дата согласия")]),_c('span',[_vm._v(_vm._s(new Date(_vm.lead.company.sign_date).toLocaleDateString()))])]):_vm._e()])])])]):_vm._e()])]):_vm._e(),(_vm.lead.contracts && _vm.lead.contracts.length && _vm.lead.contracts[0])?_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.contractDropdown,
            },on:{"click":function($event){_vm.contractDropdown = !_vm.contractDropdown}}},[_c('span',[_vm._v("Договоры")]),_c('ArrowDown')],1),(_vm.contractDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},[_c('li',[_c('div',{staticClass:"tc__check"}),_c('ul',{staticClass:"tc__list"},_vm._l((_vm.lead.contracts),function(contract,index){return _c('li',{key:index},[_c('span',{staticClass:"tc__list__title"},[_c('router-link',{staticClass:"tc__list__title",attrs:{"to":{
                          name: 'contract',
                          params: { id: contract.id },
                        }}},[_vm._v(_vm._s(contract.name))])],1),_c('span',{staticClass:"tag",class:_vm.getStatusColor(contract.status)},[_vm._v(_vm._s(contract.status))])])}),0)])])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.contactDropdown,
            },on:{"click":function($event){_vm.contactDropdown = !_vm.contactDropdown}}},[_c('span',[_vm._v("Контакты")]),_c('ArrowDown')],1),(_vm.contactDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},_vm._l((_vm.lead.company.contacts),function(contact){return _c('li',{key:contact._id},[_c('ul',{staticClass:"tc__list"},[_c('li',[_c('router-link',{staticClass:"tc__list__title",attrs:{"to":{
                        name: 'contact',
                        params: { id: contact._id },
                      }}},[_vm._v(_vm._s(contact.name))]),(contact.post)?_c('span',{staticClass:"tc__list__subtitle"},[_vm._v(_vm._s(contact.post))]):_vm._e()],1),(contact.phone.length)?_c('li',[_c('span',{staticClass:"tc__list__title"},[_vm._v("Телефон")]),_c('a',{staticClass:"tc__list__subtitle",attrs:{"href":("tel:" + (contact.phone[0]))}},[_vm._v(_vm._s(contact.phone[0]))]),(!_vm.callTimeout)?_c('i',{on:{"click":function($event){return _vm.callPhone(contact.phone[0])}}},[_c('PhoneOutIcon')],1):_c('i',{staticStyle:{"cursor":"wait"}},[_vm._v(" X ")])]):_vm._e(),(contact.email)?_c('li',[_c('span',{staticClass:"tc__list__title"},[_vm._v("Email")]),_c('a',{staticClass:"tc__list__subtitle",attrs:{"href":("mailto:" + (contact.email.split(';').join(', ')))}},[_vm._v(_vm._s(contact.email.split(";").join(", ")))]),_c('i')]):_vm._e()])])}),0)]):_vm._e()])]),_c('div',{staticClass:"workspace_left__row accordion"},[_c('div',{staticClass:"workspace_body__item accordion__item"},[_c('h3',{class:{
              trade_content__title: true,
              active: _vm.fileDropdown,
            },on:{"click":function($event){_vm.fileDropdown = !_vm.fileDropdown}}},[_c('span',[_vm._v("Документы")]),_c('ArrowDown')],1),(_vm.fileDropdown)?_c('div',{staticClass:"accordion__content"},[_c('ul',{staticClass:"tc"},_vm._l((_vm.groupBy(
                    _vm.lead.company.files.concat( _vm.lead.files,
                      [_vm.lead.contracts && _vm.lead.contracts.length ? _vm.lead.contracts.map(function (elem) { return elem.files; }) : []]
                    ).flat(Infinity),
                    'view'
                  )),function(category,index){return _c('li',{key:index},[_c('div',{staticClass:"tc__check"}),_c('ul',{staticClass:"tc__list"},[_c('li',[_c('strong',[_vm._v(_vm._s(category.title))])]),_vm._l((category.items),function(doc,index){return _c('li',{key:index},[_c('span',{staticClass:"tc__list__title"},[_vm._v(_vm._s(doc.name))]),_c('a',{staticClass:"tc__list__subtitle",attrs:{"href":'https://' + doc.path,"target":"_blank","download":""}},[_vm._v("Скачать ("+_vm._s(doc.ext)+")")])])})],2)])}),0)]):_vm._e()])])])]):_c('div',{staticClass:"manager-desktop-menu"},[_c('div',{staticClass:"sceleton"})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }