<template>
  <div class="widgets_twice_wrapper">
    <div :class="`widgets__item widget_twice ` + fields.class">
      <div class="widgets__title" v-if="fields.title">{{ fields.title }}</div>
      <div class="widgets__content" v-if="fields.number && $store.state.me.role !==  'partner'" @click="resolvePath(fields)" >{{ fields.number }}</div>
      <div class="widgets__content--client" v-if="fields.number && $store.state.me.role === 'partner'" >{{ fields.number }}</div>
    </div>
    <div class="widget_twice_bottom" v-if="fields.content">{{ fields.content }}</div>
  </div>
</template>

<script>
export default {
  props: [
    'fields',
    'filter'
  ],
  methods: {
    resolvePath (item) {
      this.setEmptyTradesFilters()
      this.setEmptyTasksFilters()
      this.setEmptyContractsFilters()

      if (item.filter) Object.assign(this.$store.state.data[item.route].filter, item.filter)
      if (item.checked) this.$store.state.data[item.route].checked = true
      if (this.filter.group_id) this.$store.state.data[item.route].filter.group_id = this.filter.group_id
      if (this.filter.responsible_user_id) this.$store.state.data[item.route].filter.responsible_user_id = this.filter.responsible_user_id
      this.$router.push(item.route)
    },
    setEmptyTradesFilters () {
      this.$store.state.data.trades.filter.status = null
      this.$store.state.data.trades.filter.responsible_user_id = null
      this.$store.state.data.trades.filter.group_id = []
      this.$store.state.data.trades.filter.tags = []
      this.$store.state.data.trades.filter.created_by = null
      this.$store.state.data.trades.checked = false
    },
    setEmptyContractsFilters () {
      this.$store.state.data.contracts.filter.status = null
      this.$store.state.data.contracts.filter.responsible_user_id = null
      this.$store.state.data.contracts.filter.group_id = []
      this.$store.state.data.contracts.filter.tags = []
      this.$store.state.data.contracts.filter.signed = null
      this.$store.state.data.contracts.filter.unloaded = null
      this.$store.state.data.contracts.filter.advance = null
    },
    setEmptyTasksFilters () {
      this.$store.state.data.tasks.filter.responsible_user_id = null
      this.$store.state.data.tasks.filter.group_id = []
      this.$store.state.data.tasks.filter.status = null
    }
  }
}
</script>

<style lang="stylus">

.widgets_twice_wrapper
  display flex
  flex-direction column

.widget_twice
  flex 1

  .widgets__content
    font-size 1.8em
    font-family font_bold

    +below(1100px)
      font-size 1.5em

    +below(768px)
      font-size 1.2em

    +below(480px)
      font-size 1em
    &:hover
      text-decoration: underline
      cursor: pointer

    &--client
          color #219653
          text-align center
          padding 15px
          font-size 1.8em
          font-family font_bold

          +below(1100px)
            font-size 1.5em

          +below(768px)
            font-size 1.2em

          +below(480px)
            font-size 1em
.widget_twice_bottom
  background $white
  border 1px solid $darkgray
  margin-top 10px
  border-radius 10px
  padding 10px
  text-align center
  overflow hidden

</style>
