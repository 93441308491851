<template>
  <svg viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M19.8976 3.04261C19.8903 2.87175 19.7543 2.73632 19.5878 2.73632H10.1134L7.34805 0.0860638C7.29049 0.0303755 7.21456 0 7.1374 0H2.79214C2.70641 0 2.62436 0.0367037 2.56557 0.101252L0.0855402 2.83631C0.027979 2.89959 -0.00141397 2.98312 0.00226015 3.06919L0.621962 17.6937C0.62931 17.8646 0.765253 18 0.931813 18H20.2087C20.2932 18 20.374 17.9646 20.4328 17.9013C20.4916 17.838 20.5222 17.7532 20.5186 17.6659L19.8976 3.04261Z" fill="#00998E"></path>
      <path d="M19.0468 5.29806L0.979871 5.91822C0.808412 5.92455 0.674919 6.07263 0.679818 6.24982L1.03253 17.2306C1.03866 17.4078 1.18195 17.5457 1.35341 17.5406L19.4203 16.9205C19.5917 16.9142 19.7252 16.7661 19.7203 16.5889L19.3676 5.60814C19.3615 5.43095 19.2182 5.29173 19.0468 5.29806Z" fill="#E6E6E6"></path>
      <path d="M19.9025 4.12241L1.82703 3.8389C1.65557 3.83637 1.51473 3.97686 1.51228 4.15405L1.35062 15.1398C1.34817 15.317 1.48534 15.4626 1.6568 15.4651L19.7322 15.7486C19.9037 15.7511 20.0445 15.6107 20.047 15.4335L20.2087 4.44768C20.2099 4.27175 20.0727 4.1262 19.9025 4.12241Z" fill="#F2F2F2"></path>
      <path d="M20.841 4.75724L2.8941 2.51705C2.72386 2.49554 2.56955 2.62084 2.54873 2.79676L1.27381 13.7041C1.25299 13.88 1.37424 14.0395 1.54447 14.061L19.4913 16.3025C19.6616 16.324 19.8159 16.1987 19.8367 16.0227L21.1116 5.11542C21.1324 4.93823 21.0112 4.77876 20.841 4.75724Z" fill="white"></path>
      <path d="M22.9251 1.6976C22.8663 1.62546 22.7806 1.58496 22.6887 1.58496H13.0919C13.0135 1.58496 12.9388 1.61534 12.8813 1.67102L10.1159 4.32129H3.41305C3.26487 4.32129 3.1375 4.43013 3.1081 4.57948L0.625621 17.6181C0.60725 17.7118 0.631744 17.8093 0.69053 17.8839C0.749316 17.9573 0.837495 18.0004 0.930573 18.0004H20.2075C20.3593 18.0004 20.4891 17.8865 20.5136 17.7308L22.9961 1.95579C23.0108 1.86467 22.9851 1.76975 22.9251 1.6976Z" fill="#00BFB1"></path>
      <path d="M0.69053 17.8839C0.749316 17.9573 0.837495 18.0003 0.929348 18.0003H20.2075C20.3593 18.0003 20.4891 17.8864 20.5136 17.7308L21.235 13.1504L0.62562 17.6181C0.608474 17.7118 0.631744 17.8092 0.69053 17.8839Z" fill="#00B3A5"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="23" height="18" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'FolderIcon'
}
</script>
