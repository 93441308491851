<template>
  <div class="lsg-tasks">
    <!-- <div class="main__header">
      <div class="main__header__item left">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="filter_hide">
          <span class="btn btn_ico" @click="$store.state.data.trades.showFilter = !$store.state.data.trades.showFilter" :class="{'disable': $store.state.data.trades.showFilter}">
            <FilterIco/>
            Скрыть фильтры
          </span>
        </mq-layout>
        <mq-layout v-if="$store.state.me.role !== 'partner'" :mq="['xlg', 'xl']" class="filter_hide">
          <div class="small-buttons">
            <a class="btn btn--small" href="#" @click.prevent="callCenter = !callCenter" :class="{ active: callCenter }">
              <PhoneIcon/>
            </a>
            <a class="btn btn--small" href="#" @click.prevent="internet = !internet" :class="{ active: internet }">
              <InternetIcon/>
            </a>
          </div>
        </mq-layout>
      </div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div> -->
    <div v-if="$store.state.me.role !== 'partner'" class="main__header main__header--secondary">
      <div class="toper__item">
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg']" class="small-buttons">
          <a class="btn btn--small" href="#" @click.prevent="callCenter = !callCenter" :class="{ active: callCenter }">
            <PhoneIcon/>
          </a>
          <a class="btn btn--small" href="#" @click.prevent="internet = !internet" :class="{ active: internet }">
            <InternetIcon/>
          </a>
        </mq-layout>
      </div>
      <div class="toper__item">
        <div class="button-group">
          <button type="button" :class="{ active: $store.state.data.osContracts.sort2 === 'today' }" @click="setSortType('today')">
            <span>Сегодня</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.osContracts.sort2 === 'tomorrow' }" @click="setSortType('tomorrow')">
            <span>Вчера</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.osContracts.sort2 === 'week' }" @click="setSortType('week')">
            <span>Неделя</span>
          </button>
          <button type="button" :class="{ active: $store.state.data.osContracts.sort2 === 'month' }" @click="setSortType('month')">
            <span>Месяц</span>
          </button>
          <button type="button" @click="openPeriod = true" :class="{ active: $store.state.data.osContracts.sort2 === 'period' && $store.state.data.osContracts.sortDate }" ref="buttonPeriod" v-click-outside="vcoConfig">
            <i>
              <CalendarIcon/>
            </i>
            <span>Период</span>
            <b class="date-picker" v-show="openPeriod">
              <date-picker v-model="$store.state.data.osContracts.sortDate" ref="pickerPeriod" inline range/>
            </b>
          </button>
        </div>
      </div>
      <div class="toper__item right">
          <a href="#" @click="clearFilters">Очистить все фильтры</a>
      </div>
      <div class="toper__item"></div>
    </div>
    <div class="main__content">
      <ContractsTable v-if="isTableView" :callCenter="callCenter" :internet="internet"/>
      <ContractsKanban v-if="isKanbanView" :callCenter="callCenter" :internet="internet"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ContractsTable from '@/components/layouts/osDesktop/components/OsDesktopTable'
import ContractsKanban from '@/components/layouts/osDesktop/components/OsDesktopKanban'
import CalendarIcon from '@/components/svg/CalendarIcon'
import PhoneIcon from '@/components/svg/phone'
import InternetIcon from '@/components/svg/InternetIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: 'osDesktop',
  data () {
    return {
      title: 'Рабочий стол отдела сопровождения',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Договоры'
        }
      ],
      view: 'table',
      page: 0,
      perPage: 50,
      callCenter: false,
      internet: false,
      sort: {
        field: 'date',
        order: 'desc'
      },
      // eslint-disable-next-line vue/no-reserved-keys
      filter: {
        q: '',
        status: null,
        tags: []
      },
      openPeriod: false,
      vcoConfig: {
        handler: () => {
          this.openPeriod = false
        }
      },
      columns: [
        {
          label: 'Наименование',
          field: 'name'
        },
        {
          label: 'Дата',
          type: 'date',
          field: 'createdAt',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd.MM.yyyy'
        },
        {
          label: 'Статус',
          field: 'status'
        },
        {
          label: 'Менеджер',
          field: 'manager'
        },
        {
          label: 'Сумма',
          field: 'summ'
        }
      ],
      rows: [
        { id: 1, name: 'John', age: 20, createdAt: '', score: 0.03343 }
      ],
      stages: ['Согласование', 'Готов к финансированию', 'Профинансирован']
    }
  },
  components: {
    CalendarIcon,
    ContractsTable,
    ContractsKanban,
    DatePicker,
    InternetIcon,
    PhoneIcon
  },
  created () {
    this.page = this.$store.state.data.osContracts.page
    this.perPage = this.$store.state.data.osContracts.perPage
    this.sort = this.$store.state.data.osContracts.sort
    this.filter = this.$store.state.data.osContracts.filter

    if (window.location.hash === '') {
      this.$store.state.data.osContracts.view = 'table'
      window.location.hash = this.$store.state.data.osContracts.view
    } else {
      this.$store.state.data.osContracts.view = window.location.hash.substr(1)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.data.osContracts.page = this.page
    this.$store.state.data.osContracts.perPage = this.perPage
    this.$store.state.data.osContracts.sort = this.sort
    this.$store.state.data.osContracts.filter = this.filter
    next()
  },
  computed: {
    pageCount () {
      return Math.ceil(this._allContractsMeta / this.perPage)
    },
    getContractFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags

      return currentFilter
    },
    isTableView () {
      return this.$store.state.data.osContracts.view === 'table'
    },
    isKanbanView () {
      return this.$store.state.data.osContracts.view === 'kanban'
    }
  },
  watch: {
    '$store.state.data.osContracts.view' () {
      window.location.hash = this.$store.state.data.osContracts.view
    },
    '$store.state.data.osContracts.sortDate' () {
      if (this.$store.state.data.osContracts.sortDate) {
        this.$store.state.data.osContracts.sort2 = 'period'
      } else {
        this.$store.state.data.osContracts.sort2 = null
      }
      this.openPeriod = false
    }
  },
  methods: {
    setSortType (type) {
      if (this.$store.state.data.osContracts.sort2 === type) {
        this.$store.state.data.osContracts.sort2 = null
      } else {
        this.$store.state.data.osContracts.sort2 = type
      }
    },
    setPage (page) {
      this.page = page - 1
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
    },
    goToPrevPage (e) {
      e.preventDefault()
      if (this.page !== 0) {
        this.page--
      }
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    isDisabled (dataLength) {
      return (dataLength < this.perPage) || (this._allContractsMeta === (this.page + 1) * this.perPage)
    },
    sortingField (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order

      return currentClass
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    clearFilters () {
      this.$store.state.data.osContracts.filter.q = ''
      this.$store.state.data.osContracts.filter.st = null
      this.$store.state.data.osContracts.filter.status = null
      this.$store.state.data.osContracts.filter.status_date = null
      this.$store.state.data.osContracts.filter.tags = []
      this.$store.state.data.osContracts.filter.partner.id = null
      this.$store.state.data.osContracts.filter.partner.name = null
      this.$store.state.data.osContracts.filter.partner.inn = null
      this.$store.state.data.osContracts.filter.signed = null
      this.$store.state.data.osContracts.filter.unloaded = null
      this.$store.state.data.osContracts.filter.advance = null
    }
  }
}
</script>
