<template>
  <button class="widgets__item btn btn--size" @click="resolvePath(fields)">Досрочное погашение</button>
  <!-- <div class="widgets__item widget_fill">
    <div :class="`fill_status ` + fields.class" :style="fields.style"><fillReady/></div>
    <div class="widget_fill__content">
      <ul>
        <li v-for="(item, index) in fields.items" :key="index"><span>{{ item.title }}</span><strong>{{ item.content }}</strong></li>
      </ul>
    </div>
  </div> -->
</template>

<script>
export default {
  props: [
    'fields',
    'filter'
  ],
  methods: {
    resolvePath (item) {
      this.setEmptyTradesFilters()
      this.setEmptyTasksFilters()
      this.setEmptyContractsFilters()
      this.$store.state.buyoutModalToggle = true
      if (item.filter) Object.assign(this.$store.state.data[item.route].filter, item.filter)
      if (item.checked) this.$store.state.data[item.route].checked = true
      if (this.filter.group_id) this.$store.state.data[item.route].filter.group_id = this.filter.group_id
      if (this.filter.responsible_user_id) this.$store.state.data[item.route].filter.responsible_user_id = this.filter.responsible_user_id
      this.$router.push(item.route)
    },
    setEmptyTradesFilters () {
      this.$store.state.data.trades.filter.status = null
      this.$store.state.data.trades.filter.responsible_user_id = null
      this.$store.state.data.trades.filter.group_id = []
      this.$store.state.data.trades.filter.tags = []
      this.$store.state.data.trades.filter.created_by = null
      this.$store.state.data.trades.checked = false
    },
    setEmptyContractsFilters () {
      this.$store.state.data.contracts.filter.status = null
      this.$store.state.data.contracts.filter.responsible_user_id = null
      this.$store.state.data.contracts.filter.group_id = []
      this.$store.state.data.contracts.filter.tags = []
      this.$store.state.data.contracts.filter.signed = null
      this.$store.state.data.contracts.filter.unloaded = null
      this.$store.state.data.contracts.filter.advance = null
    },
    setEmptyTasksFilters () {
      this.$store.state.data.tasks.filter.responsible_user_id = null
      this.$store.state.data.tasks.filter.group_id = []
      this.$store.state.data.tasks.filter.status = null
    }
  }
}
</script>
