var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"compactMode":"","pagination-options":{
      enabled: true,
      perPage: 2,
      nextLabel: '',
      prevLabel: '',
      rowsPerPageLabel: 'Вывести',
      ofLabel: 'из',
      pageLabel: 'страниц',
      allLabel: 'Все'
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'outgoing_calls')?_c('span',[_c('i',[_c('PhoneOut')],1),_vm._v(" "+_vm._s(props.row.outgoing_calls)+" ")]):(props.column.field == 'transferred')?_c('span',[_c('i',[_c('MailOut')],1),_vm._v(" "+_vm._s(props.row.transferred)+" ")]):(props.column.field == 'created_projects')?_c('span',[_c('i',[_c('ConfirmIcon')],1),_vm._v(" "+_vm._s(props.row.created_projects)+" ")]):(props.column.field == 'issued_projects')?_c('span',[_c('i',[_c('FolderIcon')],1),_vm._v(" "+_vm._s(props.row.issued_projects)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Данных нет ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }