<template>
  <div :class="`widgets__item faq_widget ` + fields.class">
    <div class="faq_widgets__title" v-if="fields.title">{{ fields.title }}</div>
    <div class="faq_widgets__content" @click="resolvePath(fields)">
      <div class="faq_widget__icon">
        <QuestionIco/>
      </div>
      <!-- <div class="faq_widget__text" v-if="fields.content">{{ fields.content }}</div>
      <div class="faq_widget__number" v-if="fields.number">{{ fields.number }}</div>
      <div class="faq_widget__link" v-if="fields.link.link">
        <a :href="fields.link.link" v-if="fields.link.outside === true" target="_blank"><ExternalIcon/></a>
        <router-link v-else :to="fields.link.link"><ExternalIcon/></router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
// import ExternalIcon from '@/components/svg/ExternalIcon'
import QuestionIco from '@/components/svg/question'

export default {
  props: [
    'fields',
    'filter'
  ],
  components: {
    QuestionIco
    // ExternalIcon
  },
  methods: {
    resolvePath (item) {
      this.setEmptyTradesFilters()
      this.setEmptyTasksFilters()
      this.setEmptyContractsFilters()

      if (item.filter) Object.assign(this.$store.state.data[item.route].filter, item.filter)
      if (item.checked) this.$store.state.data[item.route].checked = true
      if (this.filter.group_id) this.$store.state.data[item.route].filter.group_id = this.filter.group_id
      if (this.filter.responsible_user_id) this.$store.state.data[item.route].filter.responsible_user_id = this.filter.responsible_user_id
      this.$router.push(item.route)
    },
    setEmptyTradesFilters () {
      this.$store.state.data.trades.filter.status = null
      this.$store.state.data.trades.filter.responsible_user_id = null
      this.$store.state.data.trades.filter.group_id = []
      this.$store.state.data.trades.filter.tags = []
      this.$store.state.data.trades.filter.created_by = null
      this.$store.state.data.trades.checked = false
    },
    setEmptyContractsFilters () {
      this.$store.state.data.contracts.filter.status = null
      this.$store.state.data.contracts.filter.responsible_user_id = null
      this.$store.state.data.contracts.filter.group_id = []
      this.$store.state.data.contracts.filter.tags = []
      this.$store.state.data.contracts.filter.signed = null
      this.$store.state.data.contracts.filter.unloaded = null
      this.$store.state.data.contracts.filter.advance = null
    },
    setEmptyTasksFilters () {
      this.$store.state.data.tasks.filter.responsible_user_id = null
      this.$store.state.data.tasks.filter.group_id = []
      this.$store.state.data.tasks.filter.status = null
    }
  }
}
</script>

<style lang="stylus">

.faq_widget
  // grid-column-start 4
  flex-direction column !important
  // grid-column-end 4
  align-items center
  min-height 100%

  +below(768px)
    grid-column-start inherit
    flex-direction column !important
    grid-column-end inherit
    position relative

.faq_widgets__title
  padding 10px 15px
  font-size 15px
  text-align center
  font-family font_bold
  width 100%
  border-bottom 1px solid $darkgray

  +below(768px)
    border-bottom 1px solid $darkgray
    width 100%

.faq_widgets__content
  flex 1
  display flex
  align-items center
  justify-content space-around
  padding 15px
  text-align center
  width 100%

  &:hover
    cursor: pointer

  &:hover .faq_widget__number
    text-decoration: underline

  +below(768px)
    flex-direction column
    justify-content space-around

.faq_widget__icon
  width 75px
  height 75px
  display flex
  align-items center
  flex-shrink 0

  > svg
    width 75px
    height 75px

    path[fill]
      fill $orange
    path
      stroke $orange

.faq_widgets__text
  font-size 15px
  font-family font_bold

.faq_widget__number
  font-size 15px
  font-family font_bold

@media only screen and (max-width: 1400px) {
  .faq_widget__number {
    font-size 1.3em
    font-family font_bold
  }
}

.faq_widget__link
  +below(768px)
    absolute right 10px bottom 10px
  a
    background $gray
    display flex
    align-items center
    justify-content center
    padding 6px
    border-radius 5px

    svg
      path
        transition stroke 0.3s

    &:hover
      background $orange

      svg
        path
          stroke $white

</style>
