<template>
  <div :class="`widgets__item widget_check ` + fields.class">
    <div class="widget_check__title" v-if="fields.title">{{ fields.title }}</div>
    <div class="widget_check__content">
      <div class="widget_check__summ check_sum">
        <ul>
          <li v-for="(item, index) in fields.items" :key="index">
            <span>{{ item.title }}</span>
            <strong>{{ item.content }}</strong>
          </li>
        </ul>
      </div>
      <div class="widget_check__graph" v-if="fields.graph">
        <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35" cy="35" r="32" fill="white" stroke="#E8E8F6" stroke-width="6"/>
          <mask id="path-2-inside-1" fill="white">
          <path d="M67.0004 35C68.657 35 70.0134 33.6546 69.8715 32.004C69.5649 28.4344 68.7114 24.927 67.3358 21.6061C65.5769 17.3597 62.9988 13.5013 59.7487 10.2513C56.4987 7.00121 52.6403 4.42313 48.3939 2.66421C45.073 1.28864 41.5656 0.435143 37.996 0.128462C36.3454 -0.0133483 35 1.34299 35 2.99965C35 4.65631 36.3465 5.98325 37.9943 6.1543C40.7753 6.44298 43.5053 7.13288 46.0981 8.20685C49.6166 9.66427 52.8136 11.8004 55.5066 14.4934C58.1996 17.1864 60.3357 20.3834 61.7931 23.9019C62.8671 26.4947 63.557 29.2247 63.8457 32.0057C64.0168 33.6535 65.3437 35 67.0004 35Z"/>
          </mask>
          <path d="M67.0004 35C68.657 35 70.0134 33.6546 69.8715 32.004C69.5649 28.4344 68.7114 24.927 67.3358 21.6061C65.5769 17.3597 62.9988 13.5013 59.7487 10.2513C56.4987 7.00121 52.6403 4.42313 48.3939 2.66421C45.073 1.28864 41.5656 0.435143 37.996 0.128462C36.3454 -0.0133483 35 1.34299 35 2.99965C35 4.65631 36.3465 5.98325 37.9943 6.1543C40.7753 6.44298 43.5053 7.13288 46.0981 8.20685C49.6166 9.66427 52.8136 11.8004 55.5066 14.4934C58.1996 17.1864 60.3357 20.3834 61.7931 23.9019C62.8671 26.4947 63.557 29.2247 63.8457 32.0057C64.0168 33.6535 65.3437 35 67.0004 35Z" stroke="#FF8000" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-2-inside-1)"/>
        </svg>
        <span>-1.2%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'fields'
  ]
}
</script>

<style lang="stylus">

.widget_check
  display flex
  flex-direction initial !important
  align-items center
  flex-wrap wrap
  padding 10px 15px

  +below(480px)
    flex-direction column !important
    grid-row span 3

.widget_check__title
  margin-right 10px
  font-family font_bold
  text-align center
  flex 1

.widget_check__content
  display flex
  align-items center

  +below(480px)
    flex-direction column

.widget_check__summ
  border-right 1px solid $dark
  padding-right 15px
  margin-right 15px

  ul
    padding 0
    margin 0
    list-style none

    li
      display flex
      align-items center
      font-size 1.2em
      line-height 1.6em

      &:last-child
        margin-bottom 0

      span
        display inline-flex
        margin-right 15px

      strong
        color $orange

  +below(480px)
    border none
    padding 0
    margin 15px 0

    ul

      li
        display block
        text-align center

        span
          display block
          margin-right 0

.widget_check__graph
  position relative
  max-width 70px
  display flex
  align-items center
  justify-content center

  +below(480px)
    ma

  svg
    width 100%
    height auto

  span
    position absolute

</style>
