<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <ApolloQuery :query="require('@/graphql/queries/AllOsContracts.gql')" :variables="{ page, perPage, filter: getContractFilter, sortField: sort.field, sortOrder: sort.order }" @result="doneLoadingContracts">
    <template slot-scope="{ result: { error, data }, isLoading }">
      <!-- <div class="messages apollo" v-if="error">
        <div class="messages__status loading" v-if="loading">Загрузка...</div>
      </div> -->
      <div class="messages apollo" v-if="error">
        <div class="messages__status error" v-if="error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
      </div>
      <div class="tbl tbl--divided">
        <!-- <fixed-header :threshold="100"> -->
          <div class="fixed_block">
            <div class="tbl__header">
              <div class="tbl__filter">
                <label class="tbl__filter__item">
                  <v-select appendToBody
                            :options="statuses"
                            placeholder="Статус договора"
                            @input="onStatusChange"
                            :value="filter.status"
                  >
                    <template slot="no-options">Нет результатов</template>
                    <template #open-indicator="{ attributes }">
                      <div v-bind="attributes">
                        <DownIcon/>
                      </div>
                    </template>
                  </v-select>
                </label>
                <label class="tbl__filter__item">
                  <CompanyInput
                    columnName="inn"
                    :currentValue="filter.partner.inn"
                    filterName="q"
                    placeholder="Компания/ИНН"
                    @change="onCompanyChange"
                  />
                </label>
                <label v-if="$store.state.me.role !== 'partner'" class="tbl__filter__item">
                  <TagsInput :tags="filter.tags ? $store.state.allTags.filter(s => filter.tags.includes(s.id)).map(item => ({value: item.id, label: item.name})) : []" @input="onTagsChanged"/>
                </label>
              </div>
              <div class="tbl__settings">
                <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
                  <a class="tbl__setting" href="#" @click.prevent="switchColumnModal">
                    <SettingsIcon/>
                  </a>
                </mq-layout>
                <div class="tbl__type">
                  <a :class="{ active: $store.state.data.osContracts.view === 'table'}" @click.prevent="$store.state.data.osContracts.view = 'table'" href="#">
                    <TypeTable/>
                  </a>
                  <a :class="{ active: $store.state.data.osContracts.view === 'kanban'}" @click.prevent="$store.state.data.osContracts.view = 'kanban'" href="#">
                    <TypeKanban/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        <!-- </fixed-header> -->
        <div v-if="$store.state.buyoutModalToggle" class="messages apollo">
          <div class="messages__status">Чтобы открыть расчёт частично-досрочного погашения выберите договор</div>
        </div>
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <vue-good-table v-if="$store.state.me.role !== 'partner'" :columns="$store.state.data.osContracts.columns" :pagination-options="{enabled: true, perPage: perPage}" :rows="data ? data.allContracts : []" @on-selected-rows-change="selectedRowsChange" @on-sort-change="sortingField" compactMode>
            <template slot="table-row" slot-scope="props">
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'createdAt'">{{ getDate(props.row.date) }}</router-link>
              <router-link :class="getStatusColor(props.row.status)" :to="{ name: 'contract', params: { id: props.row.id } }" class="tag" v-else-if="props.column.field === 'status'">{{ props.row.status }}</router-link>
              <!-- <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'manager'">{{ props.row.manager && props.row.manager.name ? props.row.manager.name : '' }}</router-link> -->
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'partner'">{{ props.row.partner && props.row.partner.name ? props.row.partner.name : '' }}</router-link>
              <!-- <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'summ'">{{ prettifyPrice(props.row.summ) }}</router-link> -->
              <!-- <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'nextPayment'">{{ getDate(props.row.date_payment) }}</router-link> -->
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'property'">{{ props.row.property_name }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'debt' && props.row.debt === '0'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'debt'  && props.row.debt !== '0'" class="tag tag--red">{{ prettifyPrice(props.row.debt) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'payments'">{{ prettifyPrice(props.row.summ - remainder(props.row.payments)) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'duration'">{{ duration(props.row.payments) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz1' && props.row.dz1 === '0'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz1' && props.row.dz1 && props.row.dz1 !== '0'" style="font-weight:bold; color: red">{{ prettifyPrice(props.row.dz1) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz2' && props.row.dz1 === '0'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz2' && props.row.dz2 && props.row.dz2 !== '0'" style="font-weight:bold; color: red">{{ prettifyPrice(props.row.dz2) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz3' && props.row.dz3 === '0'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'dz3' && props.row.dz3 && props.row.dz3 !== '0'" style="font-weight:bold; color: red">{{ prettifyPrice(props.row.dz3) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'comment' && props.row.note" class="truncate-text">{{ props.row.note.text }}</router-link>
              <template v-if="props.column.field === 'phone' && props.row.phone">
                <div class="small-buttons">
                  <button type="button" class="btn btn--small" @click="callPhone(props.row.phone)" v-tooltip:top="props.row.phone">
                    <PhoneIcon/>
                  </button>
                </div>
              </template>
              <!-- <span v-else>{{props.formattedRow[props.column.field]}}</span> -->
            </template>
            <div slot="emptystate">
              <div v-if="loading" class="messages__status">Идёт загрузка данных...</div>
              <div v-else>Данных нет</div>
            </div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__row-count vgt-pull-left">
                  <span class="footer__row-count__label">Показано</span>
                  <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                </div>
              </div>
              <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </template>
          </vue-good-table>
          <vue-good-table v-else
            :columns="$store.state.data.osContracts.columns"
            :pagination-options="{enabled: true, perPage: perPage}"
            :rows="data ? data.allContracts : []"
            :select-options="{ selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }"
            @on-selected-rows-change="selectedRowsChange"
            @on-sort-change="sortingField"
            compactMode
          >
            <template slot="table-row" slot-scope="props">
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-if="props.column.field === 'name'">{{ props.row.name }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'createdAt'">{{ getDate(props.row.date) }}</router-link>
              <router-link :class="getStatusColor(props.row.status)" :to="{ name: 'contract', params: { id: props.row.id } }" class="tag" v-else-if="props.column.field === 'status'">{{ props.row.status }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'nextPayment'">{{ getDate(props.row.date_payment) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'debt' && (props.row.debt === '0' || props.row.status !== 'Действует')"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'debt'  && props.row.debt !== '0'" class="tag tag--red">{{ prettifyPrice(props.row.debt) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'payments' && props.row.status === 'Закрыт'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'payments'">{{ prettifyPrice(props.row.summ - remainder(props.row.payments)) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'partner'">{{ props.row.partner && props.row.partner.name ? props.row.partner.name : '' }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'duration' && props.row.status === 'Закрыт'"></router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'duration' && props.row.status !== 'Согласование'">{{ duration(props.row.payments) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'summ'">{{ prettifyPrice(props.row.summ) }}</router-link>
              <router-link :to="{ name: 'contract', params: { id: props.row.id } }" v-else-if="props.column.field === 'manager'">{{ props.row.manager && props.row.manager.name ? props.row.manager.name : '' }}</router-link>
              <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
            <div slot="emptystate">Данных нет</div>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="vgt-wrap__footer vgt-clearfix">
                <div class="footer__row-count vgt-pull-left">
                  <span class="footer__row-count__label">Показано</span>
                  <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="200">200</option>
                  </select>
                </div>
              </div>
              <paginate :click-handler="setPage" :page-count="pageCount" :value="page + 1" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                <span slot="prevContent">
                  <ArrowLeftIcon/>
                </span>
                <span slot="nextContent">
                  <ArrowRightIcon/>
                </span>
              </paginate>
            </template>
          </vue-good-table>
        </mq-layout>
        <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
          <table>
            <thead>
            <tr>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('name')" @click="sortingField('name')">Наименование</span>
                  <span :class="getSortableClass('date')" @click="sortingField('date')">Дата</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('manager')" @click="sortingField('manager')">Менеджер</span>
                  <span :class="getSortableClass('status')" @click="sortingField('status')">Статус</span>
                </div>
              </th>
              <th>
                <div class="tbl__cols">
                  <span :class="getSortableClass('summ')" @click="sortingField2('summ')">Сумма</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="3">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allContracts && data.allContracts.length">
            <tr :key="index" v-for="(contract, index) in data.allContracts">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedContracts.includes(contract.id) ? 'active' : ''" v-model="$store.state.checkedContracts" :value="contract.id"></checkbox>
                  <div class="tbl__content">
                    <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.name }}</router-link>
                    <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ getDate(contract.date) }}</router-link>
                  </div>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.manager ? contract.manager.name : '' }}</router-link>
                  <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.partner ? contract.partner.name : '' }}</router-link>
                  <router-link :to="{name: 'contract', params: {id: contract.id }}" class="tag" :class="getStatusColor(contract.status)">{{ contract.status }}</router-link>
                </div>
              </td>
              <td>
                <div class="tbl__content">
                  <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.summ }}</router-link>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="5">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
          <table>
            <tbody v-if="isLoading">
            <tr>
              <td colspan="1">Загрузка...</td>
            </tr>
            </tbody>
            <tbody v-else-if="data && data.allContracts && data.allContracts.length">
            <tr :key="index" v-for="(contract, index) in data.allContracts">
              <td>
                <div class="tbl__content__container">
                  <checkbox :class-name="$store.state.checkedContracts.includes(contract.id) ? 'active' : ''" v-model="$store.state.checkedContracts" :value="contract.id"></checkbox>
                  <div class="tbl__content">
                      <!-- <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.name }}</router-link>
                      <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ getDate(contract.date) }}</router-link>
                      <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.manager ? contract.manager.name : '' }}</router-link>
                      <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.partner ? contract.partner.name : '' }}</router-link>
                      <router-link :to="{name: 'contract', params: {id: contract.id }}" class="tag" :class="getStatusColor(contract.status)">{{ contract.status }}</router-link>
                      <router-link :to="{name: 'contract', params: {id: contract.id }}">{{ contract.summ }}</router-link>
                       -->
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">{{ contract.name }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">{{ contract.partner && contract.partner.name ? contract.partner.name : '' }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">{{ contract.property_name }}</router-link>
                      <router-link :class="getStatusColor(contract.status)" :to="{ name: 'contract', params: { id: contract.id } }" class="tag">{{ contract.status }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }" class="tag tag--red">{{ prettifyPrice(contract.debt) }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">Тек. мес.: <span style="font-weight:bold; color: red">{{ prettifyPrice(contract.dz1) }}</span></router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">2-й мес.: <span style="font-weight:bold; color: red">{{ prettifyPrice(contract.dz2) }}</span></router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">От 3-х и более: <span style="font-weight:bold; color: red">{{ prettifyPrice(contract.dz3) }}</span></router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">Остаток платежей: {{ prettifyPrice(contract.summ - remainder(contract.payments)) }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }">Остаток срока: {{ duration(contract.payments) }}</router-link>
                      <router-link :to="{ name: 'contract', params: { id: contract.id } }" class="truncate-text">{{ contract.note.text }}</router-link>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="1">Нет результатов</td>
            </tr>
            </tbody>
          </table>
        </mq-layout>
      </div>
      <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
        <div class="tbl__footer__item left"></div>
        <div class="tbl__footer__item">
          <paginate :page-count="pageCount" :value="page + 1" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
            <span slot="prevContent">
              <ArrowLeftIcon/>
            </span>
            <span slot="nextContent">
              <ArrowRightIcon/>
            </span>
          </paginate>
        </div>
        <div class="tbl__footer__item right">
          <label class="tbl__pagination__info">
            Показано
            <span class="bold">{{ perPage }}</span>
            из
            <span class="bold">{{ _allContractsMeta }}</span>
          </label>
        </div>
      </mq-layout>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import { extendMoment } from 'moment-range'
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import gql from 'graphql-tag'
// import FixedHeader from 'vue-fixed-header'
import GENERATE_ACT from '@/graphql/mutations/GenerateAct.gql'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'
import SettingsIcon from '@/components/svg/settings'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import DownIcon from '@/components/svg/DownIcon'
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
import PhoneIcon from '@/components/svg/phone'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'

// import GraphQLHelper from '@/helpers/GraphQLHelper'

// const _graphQlHelper = new GraphQLHelper()
const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')

export default {
  name: 'OsDesktopTable',
  props: {
    internet: {
      type: Boolean,
      required: true
    },
    callCenter: {
      type: Boolean,
      required: true
    }
  },
  beforeMount () {
    this.onStatusChange({ value: 'Действует', label: 'Действует' })
  },
  data: () => {
    return {
      title: 'Договора',
      loading: true,
      period: [
        new Date(2019, 0, 1),
        new Date(Date.now())
      ],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      stages: ['Согласование', 'Готов к финансированию', 'Профинансирован'],
      _allContractsMeta: 0,
      page: 0,
      perPage: 10,
      sort: {
        field: 'date',
        order: 'desc'
      },
      filter: {
        q: '',
        st: null,
        status: null,
        status_date: null,
        tags: [],
        partner: {
          id: null,
          name: null,
          inn: null
        },
        signed: null,
        unloaded: null,
        advance: null,
        internet: null,
        callCenter: null
      },
      statuses: [{
        value: 'Действует',
        label: 'Действует'
      }, {
        value: 'Закрыт',
        label: 'Закрыт'
      }, {
        value: 'Профинансирован',
        label: 'Профинансирован'
      }, {
        value: 'Согласование',
        label: 'Согласование'
      }, {
        value: 'Готов к финансированию',
        label: 'Готов к финансированию'
      }]
    }
  },
  components: {
    DownIcon,
    TypeKanban,
    TypeTable,
    SettingsIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    TagsInput,
    // DatePicker,
    // FixedHeader,
    CompanyInput,
    PhoneIcon
  },
  created () {
    this.page = this.$store.state.data.osContracts.page
    this.perPage = this.$store.state.data.osContracts.perPage
    this.sort = this.$store.state.data.osContracts.sort
    this.period = this.$store.state.data.osContracts.period
    this.filter = this.$store.state.data.osContracts.filter
  },
  beforeDestroy () {
    this.$store.state.data.osContracts.page = this.page
    this.$store.state.data.osContracts.perPage = this.perPage
    this.$store.state.data.osContracts.sort = this.sort
    this.$store.state.data.osContracts.period = this.period
    this.$store.state.data.osContracts.filter = this.filter
  },
  computed: {
    pageCount () {
      return Math.ceil(this._allContractsMeta / this.perPage)
    },
    getContractFilter () {
      let currentFilter = {}
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filter.status_date = this.getRange(this.$store.state.data.osContracts.sort2)
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.status) currentFilter.status = this.filter.status
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.filter.status_date) currentFilter.status_date = this.filter.status_date
      if (this.filter.partner.id) currentFilter.company_id = this.filter.partner.id
      if (this.filter.signed !== null) currentFilter.signed = this.filter.signed
      if (this.filter.unloaded !== null) currentFilter.unloaded = this.filter.unloaded
      if (this.filter.advance !== null) currentFilter.advance = this.filter.advance
      if (this.internet) currentFilter.internet = this.internet
      if (this.callCenter) currentFilter.callCenter = this.callCenter
      return currentFilter
    }
  },
  apollo: {
    _allContractsMeta () {
      return {
        query: gql`query($filter: ContractFilter) {
          _allContractsMeta(filter: $filter) {
            count
          }
        }`,
        variables () {
          return {
            filter: this.getContractFilter
          }
        },
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data._allContractsMeta.count || 0
        }
      }
    }
  },
  methods: {
    transformColumns (value) {
      if (value) {
        const result = value.filter(item => item.field !== 'manager')
        return result
      }
    },
    duration (value) {
      try {
        const nowDate = new Date()
        const lastDate = new Date(value[value.length - 1].date)
        const result = lastDate.getMonth() - nowDate.getMonth() + (12 * (lastDate.getFullYear() - nowDate.getFullYear()))
        return result
      } catch (e) {
        return 'Проблема с 1С'
      }
    },
    remainder (value) {
      let paid = 0
      value.forEach(elem => {
        paid = paid + parseInt(elem.paid, 10)
      })
      return paid
    },
    onStatusChange (e) {
      if (e) {
        this.filter.status = e.value
      } else {
        this.filter.status = null
      }
      this.setPage(1)
    },
    switchColumnModal () {
      this.$store.state.data.osContracts.columnModal = !this.$store.state.data.osContracts.columnModal
    },
    selectedRowsChange ({ selectedRows }) {
      this.$store.state.checkedContracts = selectedRows.map(row => row.id)
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    downloadDocuments () {
      let url = `${process.env.VUE_APP_HTTP}/download?type=pack&name=LTsfp`
      url += '&id=' + this.$store.state.checkedContracts.join(',')
      if (this.period.length) {
        if (this.period[0]) url += '&datestart=' + this.getActDate(this.period[0])
        if (this.period.length === 2 && this.period[1]) url += '&dateend=' + this.getActDate(this.period[1])
      }
      window.open(url, '_blank')
    },
    getActDate (datetime) {
      return datetime ? moment(String(datetime)).format('YYYY-MM-DD') : ''
    },
    downloadAct () {
      this.$store.dispatch({ type: 'showOverlay', show: true, text: 'Подождите, идет обработка...' })
      let partnerIds = this.$store.state.checkedContracts.filter(contract => {
        let c = this.$store.state.allContracts.find(c => c.id === contract)
        if (c && c.partner && c.partner.id) {
          return true
        }
      }).map(contract => {
        let c = this.$store.state.allContracts.find(c => c.id === contract)
        if (c && c.partner && c.partner.id) {
          return c.partner.id
        }
      }).filter((value, index, self) => {
        return self.indexOf(value) === index
      })
      partnerIds.forEach(partnerId => {
        this.$apollo.mutate({
          mutation: GENERATE_ACT,
          variables: {
            partnerId: partnerId,
            period: {
              start: this.period[0],
              end: this.period[1]
            }
          },
          update: (store, { data: { getActSverki } }) => {
            this.$store.dispatch({ type: 'hideOverlay' })
            if (getActSverki) {
              window.open(`${process.env.VUE_APP_HTTP}/download?type=as&name=LTas&id=` + getActSverki, '_blank')
            } else {
              this.$notify({
                group: 'lsg-notify',
                duration: 10000,
                text: 'Ошибка формирования акта сверки. Обратитесь в тех. поддержку.'
              })
            }
          }
        }).catch(() => {
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'Ошибка формирования акта сверки. Обратитесь в тех. поддержку.'
          })
        })
      })
    },
    selectThisMonth (emit) {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)
      emit([firstDay, lastDay])
    },
    selectThisYear (emit) {
      let date = new Date()
      let y = date.getFullYear()
      var firstDay = new Date(y, 0, 1)
      var lastDay = new Date(y, 11, 31)
      emit([firstDay, lastDay])
    },
    selectLastYear (emit) {
      let date = new Date()
      let y = date.getFullYear() - 1
      var firstDay = new Date(y, 0, 1)
      var lastDay = new Date(y, 11, 31)
      emit([firstDay, lastDay])
    },
    doneLoadingContracts ({ data, loading }) {
      this.$store.commit('allContracts', data)
      this.loading = loading
    },
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DD'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DD'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DD')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.osContracts.sortDate[0]).startOf('day').format('YYYY-MM-DD'),
            end: _moment(this.$store.state.data.osContracts.sortDate[1]).endOf('day').format('YYYY-MM-DD')
          }
        default:
          return null
      }
    },
    getStatusColor (status) {
      switch (status) {
        case 'Готов к финансированию':
          return 'tag--purple'
        case 'Профинансирован':
          return 'tag--green'
        case 'Согласование':
          return 'tag--orange'
        case 'Действует':
          return 'tag--dark-blue'
      }
    },
    setPage (page) {
      this.page = page - 1
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.setPage(1)
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    sortingField (params) {
      this.sort.order = params[0].type
      this.sort.field = params[0].field
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    sortingField2 (columnName) {
      if (columnName === this.sort.field) this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc'
      this.sort.field = columnName
    },
    getSortableClass (columnName) {
      let currentClass = 'lsg-table-sort'
      if (columnName === this.sort.field) currentClass += ' sort-' + this.sort.order
      return currentClass
    },
    onCompanyChange (item) {
      if (item) {
        this.filter.partner.id = item._id
        this.filter.partner.name = item.name
        this.filter.partner.inn = item.inn
        this.setPage(1)
      }
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    }
  }
}
</script>

<style lang="stylus">

.truncate-text {
  overflow hidden
  display -webkit-box
  -webkit-line-clamp 3
  -webkit-box-orient vertical
  line-height 1.3em
  height 3.9em
  max-width 300px
}

.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}

</style>
