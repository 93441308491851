<template>
  <div class="widgets_twicefill_wrapper">
    <div :class="`widgets__item widget_twicefill ` + fields.class">
      <div class="widgets__title" v-if="fields.title">{{ fields.title }}</div>
      <div class="widgets__content" v-if="fields.number">{{ fields.number }}</div>
    </div>
    <div class="widget_twicefill_bottom" v-if="fields.fill_text">{{ fields.fill_text }}</div>
  </div>
</template>

<script>
export default {
  props: [
    'fields'
  ]
}
</script>

<style lang="stylus">

.widgets_twicefill_wrapper
  display flex
  flex-direction column

.widget_twicefill
  flex 1

  .widgets__content
    font-size 1.8em
    font-family font_bold

.widget_twicefill_bottom
  background $white
  border 1px solid $darkgray
  margin-top 10px
  border-radius 10px
  padding 10px
  text-align center
  position relative
  overflow hidden
  z-index 1

  &::after
    content ''
    absolute left top
    width 40%
    height 100%
    background $green
    z-index -1

</style>
