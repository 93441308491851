<template>
  <div :class="`widgets__item widget_splitpercent ` + fields.class">
    <div v-for="(item, index) in fields.items" :key="index" :class="`widget_split__item ` + item.class">
      <div class="widgets__title" v-if="item.title">{{ item.title }}</div>
      <div class="widgets__content" v-if="item.number">
        <svg viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="index === 0">
          <circle cx="39.5" cy="39.5" r="36.5" fill="white" stroke="#E8E8F6" stroke-width="6"/>
          <path d="M23.2766 48.9624V54.5171C23.2746 55.0328 23.3752 55.5432 23.5719 56.0157C23.7687 56.4881 24.0573 56.9123 24.4191 57.2609C24.781 57.6095 25.2083 57.8749 25.6735 58.0401C26.1387 58.2053 26.6317 58.2666 27.1208 58.2202C32.5471 57.6011 37.7593 55.6542 42.3389 52.5359C46.5995 49.6932 50.2118 45.9002 52.9192 41.4265C55.8994 36.5962 57.754 31.0967 58.3329 25.3735C58.3769 24.8615 58.319 24.3454 58.1627 23.8582C58.0064 23.371 57.7552 22.9233 57.4251 22.5436C57.095 22.1639 56.6932 21.8605 56.2454 21.6528C55.7975 21.4451 55.3133 21.3376 54.8237 21.3371H49.5335C48.6777 21.3282 47.8481 21.6465 47.1992 22.2324C46.5503 22.8183 46.1265 23.632 46.0067 24.5218C45.7835 26.2994 45.3694 28.0448 44.7724 29.7247C44.5351 30.3874 44.4838 31.1076 44.6244 31.8001C44.765 32.4925 45.0918 33.1281 45.5659 33.6315L47.8054 35.983C45.2951 40.6184 41.6398 44.4565 37.225 47.0923L34.9855 44.7408C34.5061 44.243 33.9008 43.9 33.2413 43.7523C32.5819 43.6046 31.8959 43.6585 31.2648 43.9076C29.6649 44.5345 28.0026 44.9693 26.3096 45.2037C25.453 45.3306 24.6707 45.7837 24.1115 46.4767C23.5523 47.1697 23.2551 48.0544 23.2766 48.9624Z" fill="#E8E8F6"/>
          <path d="M35.5322 26.8916C33.8099 27.2444 32.2269 28.1289 30.9861 29.4318C29.7452 30.7347 28.9029 32.3968 28.5668 34.2053M35.5322 19.4854C31.9538 19.9028 28.6169 21.5853 26.0694 24.2568C23.522 26.9283 21.9153 30.43 21.5133 34.1868" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <mask id="path-4-inside-1" fill="white">
          <path d="M76.886 39.5C78.0535 39.5 79.0059 38.5528 78.9434 37.3869C78.7042 32.9219 77.7083 28.5245 75.9932 24.384C74.0082 19.5916 71.0986 15.2372 67.4307 11.5693C63.7628 7.90137 59.4084 4.99182 54.616 3.00676C50.4755 1.2917 46.0781 0.295755 41.6131 0.0565578C40.4472 -0.00589959 39.5 0.946488 39.5 2.11404C39.5 3.28159 40.4473 4.22148 41.6128 4.29141C45.5223 4.52601 49.3705 5.41045 52.998 6.91299C57.2774 8.68557 61.1657 11.2837 64.441 14.559C67.7163 17.8343 70.3144 21.7226 72.087 26.002C73.5896 29.6295 74.474 33.4777 74.7086 37.3872C74.7785 38.5527 75.7184 39.5 76.886 39.5Z"/>
          </mask>
          <path d="M76.886 39.5C78.0535 39.5 79.0059 38.5528 78.9434 37.3869C78.7042 32.9219 77.7083 28.5245 75.9932 24.384C74.0082 19.5916 71.0986 15.2372 67.4307 11.5693C63.7628 7.90137 59.4084 4.99182 54.616 3.00676C50.4755 1.2917 46.0781 0.295755 41.6131 0.0565578C40.4472 -0.00589959 39.5 0.946488 39.5 2.11404C39.5 3.28159 40.4473 4.22148 41.6128 4.29141C45.5223 4.52601 49.3705 5.41045 52.998 6.91299C57.2774 8.68557 61.1657 11.2837 64.441 14.559C67.7163 17.8343 70.3144 21.7226 72.087 26.002C73.5896 29.6295 74.474 33.4777 74.7086 37.3872C74.7785 38.5527 75.7184 39.5 76.886 39.5Z" stroke="#FF8000" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-4-inside-1)"/>
        </svg>
      <span>{{ item.number }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'fields'
  ]
}
</script>

<style lang="stylus">

.widget_splitpercent
  .widgets__title
    padding 0
    border none

  .widgets__content
    padding 0
    font-size 2em
    line-height normal
    font-family font_bold
    display flex
    align-items center
    justify-content center

    svg
      width 100%
      height auto
      max-width 80px
      position absolute
      z-index -1

      +below(768px)
        display none

.widget_split__item
  border-bottom 1px solid $darkgray
  padding 10px 15px
  flex 1

  &:last-child
    border none

</style>
